<template>
    <div class="container-xxl p-0">
        <router-view />
    </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss">

</style>
