import { ContactUsForm } from "../models/contactUsFormData";
import emailjs,{ EmailJSResponseStatus } from '@emailjs/browser';
import { IEmailService } from "../interfaces/emailServiceInterface";

export function useEmailServiceBeasy(): IEmailService {
    
    const serviceID = process.env.VUE_APP_EMAILJS_SERVICE_ID;
    const userID = process.env.VUE_APP_EMAILJS_USER_ID;
    const templateMailBeasy = process.env.VUE_APP_EMAILJS_TEMPLATE_MAIL_BEASY;
    const templateMailCustomer = process.env.VUE_APP_EMAILJS_TEMPLATE_MAIL_CUSTOMER;

    const sendEmailToBeasy = async (data: ContactUsForm): Promise<EmailJSResponseStatus> => {
      return emailjs.send(serviceID, templateMailBeasy,
            {
              name: data.name,
              surname: data.surname,
              email: data.email
            }, 
            userID);
    }

    const sendEmailToCustomer = async (data: ContactUsForm): Promise<EmailJSResponseStatus> => {
      return emailjs.send(
            serviceID, 
            templateMailCustomer, 
            {
              name: data.name,
              email: data.email
            }, 
            userID);
    }

    return {
        sendEmailToBeasy,
        sendEmailToCustomer,
    };
}