import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/images/icons/icon_cookies.svg'


const _hoisted_1 = {
  key: 0,
  class: "container-fluid fixed-bottom d-flex justify-content-center p-3"
}
const _hoisted_2 = { class: "row bb-background-coockies align-items-center px-2 py-3 gx-5 col-md-12 col-lg-10 col-11" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-sm-5 mb-3 mb-sm-0 text-center text-sm-start align-self-center" }
const _hoisted_6 = { class: "bb-txt-c m-1" }
const _hoisted_7 = { class: "col-sm-5 align-self-center text-end" }
const _hoisted_8 = {
  class: "btn-group",
  role: "group",
  "aria-label": "Basic example"
}

import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';
import Cookies from 'js-cookie';

const coockieConsentItem = 'cookieConsent';
const coockieConsentAccepted = 'accepted';


export default /*@__PURE__*/_defineComponent({
  __name: 'CoockiesBar',
  props: {
  fromBlackship:{
    type: Boolean
  }
},
  setup(__props) {

const isVisible = ref(false);
const router = useRouter();

const props = __props


onMounted(() => {
    const coockiesAccepted = Cookies.get(coockieConsentItem);
    if(!coockiesAccepted || coockiesAccepted !== coockieConsentAccepted){
        isVisible.value = true;
    }
});

function acceptCookies(){
    Cookies.set(coockieConsentItem, coockieConsentAccepted);
    isVisible.value = false;
}

function goToTermsAndConditions(){
    router.push("termsandconditions");
}
function goToTermsAndConditionsBl(){
    router.push("termsandconditions-bl");
}


return (_ctx: any,_cache: any) => {
  return (isVisible.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-sm-2 mb-3 mb-sm-0 align-self-center text-center" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  alt: "",
                  class: "img-fluid"
                })
              ], -1)),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('COOKIES_DESC')), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("a", {
                    class: "btn btn-sm bb-background-button text-light text-uppercase fw-bold rounded-pill me-2 bb-txt-c",
                    role: "button",
                    onClick: acceptCookies
                  }, _toDisplayString(_ctx.$t('COOKIES_BTN_ACCEPT')), 1),
                  (!props.fromBlackship)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        class: "btn btn-sm bb-background-button text-light text-uppercase fw-bold rounded-pill bb-txt-c",
                        role: "button",
                        onClick: goToTermsAndConditions
                      }, _toDisplayString(_ctx.$t('COOKIES_BTN_READ')), 1))
                    : _createCommentVNode("", true),
                  (props.fromBlackship)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        class: "btn btn-sm bb-background-button text-light text-uppercase fw-bold rounded-pill bb-txt-c",
                        role: "button",
                        onClick: goToTermsAndConditionsBl
                      }, _toDisplayString(_ctx.$t('COOKIES_BTN_READ')), 1))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})