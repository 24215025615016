<template>
    <NavbarBlackshipComponent />
    <div class="container-xxl">
        <div class="row bb-backgroud bb-padding-top bb-padding">
            <div class="col-sm-12 mb-5">
                <h4 class="bb-txt-sec-name mb-4">{{ $t('TERMS_1') }}</h4>
                <h2 class="bb-txt-sec-title mb-4">{{ $t('TERMS_2') }}</h2>
            </div>
        </div>
        <div class="row bb-padding">
            <div class="col-sm-12 py-3 mb-5" style="background: white;">
                <p class="bb-info-title">{{ $t('TERMS_3') }}</p>
                <p class="bb-info-txt">{{ $t('TERMS_4') }}<br>
                    <br>{{ $t('TERMS_5') }}
                    <br>{{ $t('TERMS_6') }}
                    <br>{{ $t('TERMS_7') }}
                    <br>{{ $t('TERMS_8') }}
                    <br>
                    <br>{{ $t('TERMS_9') }}
                </p>
                <hr>
                <p class="bb-info-title">{{ $t('TERMS_10') }}</p>
                <p class="bb-info-subtitle">{{ $t('TERMS_11') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_12') }}</p>
                
                <p class="bb-info-subtitle">{{ $t('TERMS_13') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_14') }}</p>

                <p class="bb-info-subtitle">{{ $t('TERMS_15') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_16', { email: 'info@beasy4biz.com' }) }}</p>
                
                <p class="bb-info-subtitle">{{ $t('TERMS_17') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_18') }}</p>

                <p class="bb-info-subtitle">{{ $t('TERMS_19') }}</p>
                <p class="bb-info-txt">{{ $t('TERMS_20') }}</p>
                <ol class="bb-info-txt mb-3" type="a">
                    <li>{{ $t('TERMS_21') }}</li>
                    <li>{{ $t('TERMS_22') }}</li>
                </ol>

                <p class="bb-info-subtitle">{{ $t('TERMS_23') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_24') }}</p>

                <hr>

                <p class="bb-info-txt mb-3">{{ $t('TERMS_25') }}</p>
                <p class="bb-info-subtitle">{{ $t('TERMS_26') }}</p>
                <p class="bb-info-txt">{{ $t('TERMS_27') }}</p>
                <p class="bb-info-txt mb-3"><b>{{ $t('TERMS_28') }}</b>{{ $t('TERMS_29') }}</p>
                <ul class="bb-info-txt mb-3">
                    <li>{{ $t('TERMS_30') }}</li>
                    <li>{{ $t('TERMS_31') }}</li>
                    <li>{{ $t('TERMS_32') }}</li>
                    <li>{{ $t('TERMS_33') }}</li>
                </ul>


                <p class="bb-info-txt"><b>{{ $t('TERMS_34') }}</b>{{ $t('TERMS_35') }}</p>

                <ul>
                    <li>
                        <p class="bb-info-subtitle">{{ $t('TERMS_36') }}</p>
                        <p class="bb-info-txt">{{ $t('TERMS_37') }}</p>
                    </li>
                    <li>
                        <p class="bb-info-subtitle">{{ $t('TERMS_38') }}</p>
                        <p class="bb-info-txt mb-3">{{ $t('TERMS_39') }}</p>
                        <ul>
                            <li>
                                <p class="bb-info-txt mb-3">{{ $t('TERMS_40') }}</p>
                            </li>
                            <li>
                                <p class="bb-info-txt mb-3">{{ $t('TERMS_41') }}</p>
                            </li>
                        </ul>
                    </li>

                </ul>

                <p class="bb-info-txt mb-3">{{ $t('TERMS_42') }}</p>

                <p class="bb-info-subtitle">{{ $t('TERMS_43') }}</p>
                <p class="bb-info-txt">{{ $t('TERMS_44') }}</p>

                <ol class="bb-info-txt mb-3">
                    <li>{{ $t('TERMS_45') }}</li>
                    <li>{{ $t('TERMS_46') }}</li>
                    <li>{{ $t('TERMS_47') }}</li>
                    <li>{{ $t('TERMS_48') }}</li>
                </ol>
                <p class="bb-info-subtitle">{{ $t('TERMS_49') }}</p>
                <p class="bb-info-txt">{{ $t('TERMS_50') }}</p>

                <ul class="bb-info-txt">
                    <li>{{ $t('TERMS_51') }}</li>
                    <li>{{ $t('TERMS_52') }}</li>
                    <li>{{ $t('TERMS_53') }}</li>
                    <li>{{ $t('TERMS_54') }}</li>
                    <li>{{ $t('TERMS_55') }}</li>
                    <li>{{ $t('TERMS_56') }}</li>
                </ul>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_57') }}</p>

                <p class="bb-info-subtitle">{{ $t('TERMS_58') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_59') }}</p>

                <p class="bb-info-subtitle">{{ $t('TERMS_60') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_61') }}</p>

                <p class="bb-info-subtitle">{{ $t('TERMS_62') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_63') }}</p>
                <div style="margin-left: 2rem;">
                    <p class="bb-info-txt mb-3">
                        <span>{{ $t('TERMS_64') }}</span>
                        <br>
                        <span>{{ $t('TERMS_65') }}</span>
                    </p>
                    
                    <p class="bb-info-txt mb-3">
                        <span>{{ $t('TERMS_66') }}</span>
                        <br>
                        <span>{{ $t('TERMS_67') }}</span>
                    </p>

                    <p class="bb-info-txt mb-3">
                        <span>{{ $t('TERMS_68') }}</span>
                        <br>
                        <span>{{ $t('TERMS_69') }}</span>
                    </p>

                    <p class="bb-info-txt mb-3">
                        <span>{{ $t('TERMS_70') }}</span>
                        <br>
                        <span>{{ $t('TERMS_71') }}</span>
                    </p>

                    <ol class="bb-info-txt mb-3" type="a">
                        <li>{{ $t('TERMS_72') }}</li>
                        <li>{{ $t('TERMS_73') }}</li>
                        <li>{{ $t('TERMS_74') }}</li>
                        <li>{{ $t('TERMS_75') }}</li>  
                    </ol>
                    <p class="bb-info-txt mb-3">
                        <span>{{ $t('TERMS_76') }}</span>
                        <br>
                        <span>{{ $t('TERMS_77') }}</span>
                    </p>

                    <p class="bb-info-txt mb-3">
                        <span>{{ $t('TERMS_78') }}</span>
                        <br>
                        <span>{{ $t('TERMS_79') }}</span>
                    </p>
                    <p class="bb-info-txt mb-3">
                        <span>{{ $t('TERMS_80') }}</span>
                        <br>
                        <span>{{ $t('TERMS_81') }}</span>
                    </p>
                </div>

                <p class="bb-info-txt mb-3">{{ $t('TERMS_82', { email: 'info@beasy4biz.com' }) }}</p>

                <p class="bb-info-subtitle">{{ $t('TERMS_83') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_84') }}</p>

                <p class="bb-info-subtitle">{{ $t('TERMS_85') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_86') }}</p>
                <ul class="bb-info-txt mb-3">
                    <li>{{ $t('TERMS_87') }}</li>
                    <li>{{ $t('TERMS_88') }}</li>
                    <li>{{ $t('TERMS_89') }}</li>
                </ul>

                <p class="bb-info-txt mb-3">{{ $t('TERMS_90') }}</p>

                <p class="bb-info-subtitle">{{ $t('TERMS_91') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_92') }}</p>
                
                <hr>

                <p class="bb-info-subtitle">{{ $t('TERMS_93') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_94') }}</p>

                <p class="bb-info-subtitle">{{ $t('TERMS_95') }}</p>
                <p class="bb-info-txt">{{ $t('TERMS_96') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_97') }}</p>

                <div style="margin-left: 2rem;">
                    <p class="bb-info-subtitle">{{ $t('TERMS_98') }}</p>
                    <p class="bb-info-txt mb-3">{{ $t('TERMS_99') }}</p>
        
                    <p class="bb-info-subtitle">{{ $t('TERMS_100') }}</p>
                    <p class="bb-info-txt mb-3">{{ $t('TERMS_101') }}</p>
                </div>

                <p class="bb-info-subtitle">{{ $t('TERMS_102') }}</p>
                <div style="margin-left: 2rem;">

                    <p class="bb-info-subtitle">{{ $t('TERMS_103') }}</p>
                    <p class="bb-info-txt mb-3">{{ $t('TERMS_104') }}</p>

                    <p class="bb-info-subtitle">{{ $t('TERMS_105') }}</p>
                    <p class="bb-info-txt mb-3">{{ $t('TERMS_106') }}</p>

                    <p class="bb-info-subtitle">{{ $t('TERMS_107') }}</p>
                    <p class="bb-info-txt mb-3">{{ $t('TERMS_108') }}</p>
                </div>

                <p class="bb-info-subtitle">{{ $t('TERMS_109') }}</p>
                <ul class="bb-info-txt mb-3">
                    <li>{{ $t('TERMS_110') }}</li>
                    <li>{{ $t('TERMS_111') }}</li>
                    <li>{{ $t('TERMS_112') }}</li>
                </ul>
                <div class="table-responsive-md mt-4 mb-4">
                    <table class="table table-bordered">
                        <thead>
                            <tr class="">
                            <th scope="col" class="bb-info-subtitle col-2">{{ $t('TERMS_113') }}</th>
                            <th scope="col" class="bb-info-subtitle col-2">{{ $t('TERMS_114') }}</th>
                            <th scope="col" class="bb-info-subtitle col-2">{{ $t('TERMS_115') }}</th>
                            <th scope="col" class="bb-info-subtitle col-2">{{ $t('TERMS_116') }}</th>
                            <th scope="col" class="bb-info-subtitle col-4">{{ $t('TERMS_117') }}</th>
                            </tr>
                        </thead>
                        <tbody class="bb-info-txt">
                            <tr>
                                <td>{{ $t('TERMS_118') }}</td>
                                <td>{{ $t('TERMS_119') }}</td>
                                <td>{{ $t('TERMS_120') }}</td>
                                <td>{{ $t('TERMS_121') }}</td>
                                <td>{{ $t('TERMS_122') }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('TERMS_123') }}</td>
                                <td>{{ $t('TERMS_124') }}</td>
                                <td>{{ $t('TERMS_125') }}</td>
                                <td>{{ $t('TERMS_126') }}</td>
                                <td>{{ $t('TERMS_127') }}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span>{{ $t('TERMS_128') }}</span>
                                    <br>
                                    <span>{{ $t('TERMS_129') }}</span>
                                    <br>
                                    <span>{{ $t('TERMS_130') }}</span>
                                    <br>
                                    <span>{{ $t('TERMS_131') }}</span>
                                </td>
                                <td>
                                    <span>{{ $t('TERMS_132') }}</span>
                                    <br>
                                    <span>{{ $t('TERMS_133') }}</span>
                                    <br>
                                    <span>{{ $t('TERMS_134') }}</span>
                                </td>
                                <td>{{ $t('TERMS_135') }}</td>
                                <td>{{ $t('TERMS_136') }}</td>
                                <td>{{ $t('TERMS_137') }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('TERMS_138') }}</td>
                                <td>{{ $t('TERMS_139') }}</td>
                                <td>{{ $t('TERMS_140') }}</td>
                                <td>{{ $t('TERMS_141') }}</td>
                                <td>{{ $t('TERMS_142') }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <p class="bb-info-subtitle">{{ $t('TERMS_143') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_144') }}</p>
                <ul>
                    <li>
                        <p class="bb-info-subtitle">{{ $t('TERMS_145') }}</p>
                        <p class="bb-info-txt mb-3">{{ $t('TERMS_146') }}</p>
                    </li>
                    <li>
                        <p class="bb-info-subtitle">{{ $t('TERMS_147') }}</p>
                        <p class="bb-info-txt mb-3">{{ $t('TERMS_148') }}</p>
                    </li>
                    <li>
                        <p class="bb-info-subtitle">{{ $t('TERMS_149') }}</p>
                        <p class="bb-info-txt mb-3">{{ $t('TERMS_150') }}</p>
                    </li>
                </ul>

                <div style="margin-left: 2rem;">
                    <p class="bb-info-subtitle">{{ $t('TERMS_151') }}</p>
                    <p class="bb-info-txt mb-3">{{ $t('TERMS_152') }}</p>
        
                    <p class="bb-info-subtitle">{{ $t('TERMS_153') }}</p>
                    <p class="bb-info-txt mb-3">{{ $t('TERMS_154') }}</p>
        
                    <p class="bb-info-subtitle">{{ $t('TERMS_155') }}</p>
                    <p class="bb-info-txt mb-3">{{ $t('TERMS_156') }}</p>
        
                    <p class="bb-info-subtitle">{{ $t('TERMS_157') }}</p>
                    <p class="bb-info-txt mb-3">{{ $t('TERMS_158') }}</p>
        
                    <p class="bb-info-subtitle">{{ $t('TERMS_159') }}</p>
                    <p class="bb-info-txt mb-3">{{ $t('TERMS_160') }}</p>
                </div>

                <p class="bb-info-txt mb-3">{{ $t('TERMS_161') }}</p>


                <p class="bb-info-subtitle">{{ $t('TERMS_162') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_163') }}</p>

                <p class="bb-info-subtitle">{{ $t('TERMS_164') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_165') }}</p>

                <p class="bb-info-subtitle">{{ $t('TERMS_166') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_167') }}</p>

                <p class="bb-info-subtitle">{{ $t('TERMS_168') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_169') }}</p>

                <p class="bb-info-subtitle">{{ $t('TERMS_170') }}</p>
                <p class="bb-info-txt mb-3">{{ $t('TERMS_171') }}</p>

                <p class="bb-info-subtitle">{{ $t('TERMS_172') }}</p>
                <p class="bb-info-subtitle">{{ $t('TERMS_173') }}</p>

                <ul class="bb-info-txt mb-3">
                    <li>{{ $t('TERMS_174') }}</li>
                    <li>{{ $t('TERMS_175') }}</li>
                    <li>{{ $t('TERMS_176') }}</li>
                    <li>{{ $t('TERMS_177') }}</li>
                </ul>

                <p class="bb-info-subtitle">{{ $t('TERMS_178') }}</p>
                <ul class="bb-info-txt mb-3">
                    <li>{{ $t('TERMS_179') }}</li>
                    <li>{{ $t('TERMS_180') }}</li>
                    <li>{{ $t('TERMS_181') }}</li>
                </ul>

                <p class="bb-info-subtitle">{{ $t('TERMS_182') }}</p>
                <p class="bb-info-subtitle">{{ $t('TERMS_183') }}</p>
                <ul class="bb-info-txt mb-3">
                    <li>{{ $t('TERMS_184') }}</li>
                    <li>{{ $t('TERMS_185') }}</li>
                    <li>{{ $t('TERMS_186') }}</li>
                    <li>{{ $t('TERMS_187') }}</li>
                </ul>
                <p class="bb-info-subtitle">{{ $t('TERMS_188') }}</p>
                <ul class="bb-info-txt mb-3">
                    <li>{{ $t('TERMS_189') }}</li>
                    <li>{{ $t('TERMS_190') }}</li>
                    <li>{{ $t('TERMS_191') }}</li>
                    <li>{{ $t('TERMS_192') }}</li>
                    <li>{{ $t('TERMS_193') }}</li>
                    <li>{{ $t('TERMS_194') }}</li>
                </ul>

                <p class="bb-info-subtitle">{{ $t('TERMS_195') }}</p>
                <p class="bb-info-subtitle">{{ $t('TERMS_196') }}</p>
                <ul class="bb-info-txt mb-3">
                    <li>{{ $t('TERMS_197') }}</li>
                    <li>{{ $t('TERMS_198') }}</li>
                    <li>{{ $t('TERMS_199') }}</li>
                    <li>{{ $t('TERMS_200') }}</li>
                    <li>{{ $t('TERMS_201') }}</li>
                    <li>{{ $t('TERMS_202') }}</li>
                </ul>

                <p class="bb-info-subtitle">{{ $t('TERMS_203') }}</p>
                <ul class="bb-info-txt mb-3">
                    <li>{{ $t('TERMS_204') }}</li>
                    <li>{{ $t('TERMS_205') }}</li>
                    <li>{{ $t('TERMS_206') }}</li>
                    <li>{{ $t('TERMS_207') }}</li>
                    <li>{{ $t('TERMS_208') }}</li>
                </ul>

                <p class="bb-info-subtitle">{{ $t('TERMS_209') }}</p>
                <p class="bb-info-subtitle">{{ $t('TERMS_210') }}</p>
                <ul class="bb-info-txt mb-3">
                    <li>{{ $t('TERMS_211') }}</li>
                    <li>{{ $t('TERMS_212') }}</li>
                    <li>{{ $t('TERMS_213') }}</li>
                </ul>

                <hr>
                <p class="bb-info-subtitle">{{ $t('TERMS_214') }}</p>
            </div>
        </div>
    </div>
<FooterComponent />

</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import NavbarBlackshipComponent from "@/components/elements/NavbarBlackshipComponent.vue";
import FooterComponent from "@/components/layout/FooterComponent.vue";
onMounted(() =>{
 window.scrollTo(0,0);

});

</script>

<style scoped lang="scss">
.bb-padding-top{
    padding-top: 10rem;
}

</style>