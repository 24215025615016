import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, createTextVNode as _createTextVNode, vShow as _vShow, withModifiers as _withModifiers, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/mobile.png'
import _imports_1 from '@/assets/images/expert.png'
import _imports_2 from '@/assets/images/research.png'
import _imports_3 from '../../assets/images/blackship-team.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "bb-background-img pb-5 bb-margin-custom" }
const _hoisted_3 = { class: "row justify-content-center bb-padding pt-5 pb-5" }
const _hoisted_4 = { class: "col-sm-7 align-content-center text-sm-start text-center bb-col" }
const _hoisted_5 = { class: "bb-title mb-1" }
const _hoisted_6 = { class: "bb-title mb-4" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  class: "btn btn-lg bb-btn-gradient-green text-uppercase mt-5 rounded-pill mb-5",
  href: "#ps",
  role: "button"
}
const _hoisted_9 = { class: "col sm-5 px-0 px-xl-3" }
const _hoisted_10 = { class: "card bg-info" }
const _hoisted_11 = { class: "bb-form-title mb-4" }
const _hoisted_12 = { class: "card-text-white" }
const _hoisted_13 = { class: "form-group mb-3 text-start" }
const _hoisted_14 = {
  for: "name",
  class: "bb-form-txt"
}
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = { class: "form-group mb-3 text-start" }
const _hoisted_17 = {
  for: "surname",
  class: "bb-form-txt"
}
const _hoisted_18 = ["placeholder"]
const _hoisted_19 = { class: "form-group mb-3 text-start" }
const _hoisted_20 = {
  for: "email",
  class: "bb-form-txt"
}
const _hoisted_21 = ["placeholder"]
const _hoisted_22 = { class: "form-check mb-4 mt-4" }
const _hoisted_23 = {
  class: "form-check-label",
  for: "privacyCheck"
}
const _hoisted_24 = { class: "text-center mb-4 mt-4" }
const _hoisted_25 = ["disabled"]
const _hoisted_26 = {
  class: "alert alert-success text-center",
  role: "alert"
}
const _hoisted_27 = {
  class: "alert alert-danger text-center",
  role: "alert"
}
const _hoisted_28 = { id: "coockies-bar" }
const _hoisted_29 = { class: "pb -5" }
const _hoisted_30 = { class: "row pt-5 bb-padding" }
const _hoisted_31 = { class: "col-sm-12 bb-padding mt-3 mb-3" }
const _hoisted_32 = { class: "text-center bb-txt-sec-name-black mb-5" }
const _hoisted_33 = { class: "text-center bb-txt-sec-title-black mb-5" }
const _hoisted_34 = { class: "row justify-content-center bb-padding mb-5 pb-5" }
const _hoisted_35 = { class: "col-sm-4 px-xl-3 px-sm-0 px-2 pb-5 pb-sm-0" }
const _hoisted_36 = { class: "card-component bb-1" }
const _hoisted_37 = { class: "card-title mt-4" }
const _hoisted_38 = { class: "card-text mt-3 px-xl-4" }
const _hoisted_39 = { class: "col-sm-4 px-xl-3 px-sm-0 px-2 pb-5 pb-sm-0" }
const _hoisted_40 = { class: "card-component bb-2" }
const _hoisted_41 = { class: "card-title mt-4" }
const _hoisted_42 = { class: "card-text mt-3 px-xl-4" }
const _hoisted_43 = { class: "col-sm-4 px-xl-3 px-sm-0 px-2 pb-5 pb-sm-0" }
const _hoisted_44 = { class: "card-component bb-3" }
const _hoisted_45 = { class: "card-title mt-4" }
const _hoisted_46 = { class: "card-text mt-3 px-xl-4" }
const _hoisted_47 = { class: "pb-5 bb-backgroud" }
const _hoisted_48 = { class: "row justify-content-center bb-padding pt-5" }
const _hoisted_49 = { class: "col-sm-6 col-sm-6 align-content-center text-sm-end text-center" }
const _hoisted_50 = { class: "bb-txt-sec-title mb-4" }
const _hoisted_51 = { class: "bb-txt-sec" }
const _hoisted_52 = {
  id: "counters",
  class: "row bb-padding-counter pt-3"
}
const _hoisted_53 = { class: "col-sm-6 col-6" }
const _hoisted_54 = { class: "card-component bb-card-counter align-content-center" }
const _hoisted_55 = { class: "bb-counter-txt" }
const _hoisted_56 = { class: "bb-counter" }
const _hoisted_57 = { class: "col-sm-6 col-6 mt-5" }
const _hoisted_58 = { class: "card-component bb-card-counter align-content-center" }
const _hoisted_59 = { class: "bb-counter-txt" }
const _hoisted_60 = { class: "bb-counter" }
const _hoisted_61 = { class: "bb-background-slogan" }
const _hoisted_62 = { class: "row justify-content-center bb-padding" }
const _hoisted_63 = { class: "col-sm-12 align-content-center text-center bb-slogan" }
const _hoisted_64 = ["src"]
const _hoisted_65 = { class: "col-sm-12 align-content-center text-center bb-slogan-mobile" }
const _hoisted_66 = ["src"]
const _hoisted_67 = { class: "pb-5 bb-backgroud" }
const _hoisted_68 = { class: "row pt-5 bb-padding" }
const _hoisted_69 = { class: "col-sm-12 bb-padding mt-3 mb-3" }
const _hoisted_70 = { class: "text-center bb-txt-sec-name mb-5" }
const _hoisted_71 = { class: "text-center bb-txt-sec-title mb-5" }
const _hoisted_72 = { class: "row justify-content-sm-start justify-content-center" }
const _hoisted_73 = { class: "col-auto mx-3" }
const _hoisted_74 = { class: "btn-custom" }
const _hoisted_75 = { class: "col-auto mx-3" }
const _hoisted_76 = { class: "btn-custom" }
const _hoisted_77 = { class: "col-auto mx-3" }
const _hoisted_78 = { class: "btn-custom" }
const _hoisted_79 = { class: "row justify-content-sm-end justify-content-center pb-5 pt-sm-5 pt-0" }
const _hoisted_80 = { class: "col-auto mx-3" }
const _hoisted_81 = { class: "btn-custom" }
const _hoisted_82 = { class: "col-auto mx-3" }
const _hoisted_83 = { class: "btn-custom" }
const _hoisted_84 = { class: "col-auto mx-3" }
const _hoisted_85 = { class: "btn-custom" }

import { ref, computed, onMounted, onUnmounted } from 'vue';
import VueNumberAnimation from "vue-number-animation";
import NavbarBlackshipComponent from "@/components/elements/NavbarBlackshipComponent.vue";
import FooterComponent from "@/components/layout/FooterComponent.vue";
import CoockiesBar from '@/components/elements/CoockiesBar.vue';
import { useStore } from 'vuex';
import { SupportedLanguages } from '@/i18n/languages';
import { useEmailServiceBlackship } from '@/services/composables/useEmailServiceBlackship';
import { ContactUsForm } from '@/services/models/contactUsFormData';
import { useFirestore } from '@/services/api/useFirestore';
import { Domain } from '@/services/models/domain';
import { Outcome } from '@/services/models/outcome';

// cambio l'immagine dello slogan dinamicamente a seconda della lingua
type ObserverCallback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => void;

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeBlackshipComponent',
  setup(__props) {

const store = useStore();
const selectedLanguage = computed(() => store.state.locale);
const sloganImage = computed(() => {
  switch (selectedLanguage.value) {
    case SupportedLanguages.IT:
      return require('../../assets/images/slogan-it.svg');
    case SupportedLanguages.EN:
      return require('../../assets/images/slogan-en.svg');
    default:
      return require('../../assets/images/slogan-it.svg');
  }
});

const sloganImageMobile = computed(() => {
  switch (selectedLanguage.value) {
    case SupportedLanguages.IT:
      return require('../../assets/images/slogan-mobile-it.svg');
    case SupportedLanguages.EN:
      return require('../../assets/images/slogan-mobile-en.svg');
    default:
      return require('../../assets/images/slogan-mobile-it.svg');
  }
});

// Gestione counters. Si attivano solo quando l'utente passa sopra la porzione di schermata nella quale si trovano
const showCounters = ref(false);
const observerCallback: ObserverCallback  = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      if (entry.target.id === 'counters') showCounters.value = true;
    }
  });
};
const observer = new IntersectionObserver(observerCallback, {
  threshold: 0.1, // Inizia l'animazione quando il 10% dell'elemento è visibile
});

onMounted(() => {
  observer.observe(document.getElementById('counters') as Element);
});

onUnmounted(() => {
  observer.disconnect();
});

const theFormat =(value:number)=>{
  return Math.round(value);
}

const checkMailSuccess = ref(false);
const checkMailError = ref(false);
const emailOutcome = ref(Outcome.KO);

// Gestione form
const formData = ref({
  name: '',
  surname: '',
  email: '',
  privacyAccepted: false
});

const isFormValid = computed(() =>
    formData.value.name &&
    formData.value.surname &&
    formData.value.email &&
    formData.value.privacyAccepted
);

const submitForm = () => {
  const {sendEmailToBeasy, sendEmailToCustomer} = useEmailServiceBlackship();
  const { registerNewEmail } = useFirestore();

  checkMailError.value = false;
  checkMailSuccess.value = false;
  
  const dataToSend = new ContactUsForm(formData.value.name, formData.value.surname, formData.value.email, emailOutcome.value);

  sendEmailToBeasy(dataToSend)
  .then(() => {
    sendEmailToCustomer(dataToSend)
    .then(() =>{
      checkMailSuccess.value =true;
      dataToSend.emailOutcome = Outcome.OK;

      // Reset form data after all it's done
      formData.value.name = '';
      formData.value.surname = '';
      formData.value.email = '';
      formData.value.privacyAccepted = false;
    }).catch(mailError);
  })
  .catch(mailError)
  .finally(() => registerNewEmail(dataToSend, Domain.BLACKSHIP));
};

function mailError() {
  console.log(checkMailError.value);
  checkMailError.value = true;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(NavbarBlackshipComponent),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("section", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_1')), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_2')), 1),
            _createElementVNode("p", {
              class: "bb-subtitle",
              innerHTML: _ctx.$t('BLACKSHIP_HOMEPAGE_3')
            }, null, 8, _hoisted_7),
            _createElementVNode("a", _hoisted_8, _toDisplayString(_ctx.$t('HOME_BTN_SHOW_MORE')), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("h4", _hoisted_11, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_4')), 1),
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_5')), 1),
              _createElementVNode("form", {
                id: "form",
                onSubmit: _withModifiers(submitForm, ["prevent"])
              }, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_6')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    class: "form-control",
                    id: "name",
                    placeholder: _ctx.$t('BLACKSHIP_HOMEPAGE_30'),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((formData.value.name) = $event))
                  }, null, 8, _hoisted_15), [
                    [_vModelText, formData.value.name]
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_7')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    class: "form-control",
                    id: "surname",
                    placeholder: _ctx.$t('BLACKSHIP_HOMEPAGE_31'),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.value.surname) = $event))
                  }, null, 8, _hoisted_18), [
                    [_vModelText, formData.value.surname]
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_8')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "email",
                    class: "form-control",
                    id: "email",
                    placeholder: _ctx.$t('BLACKSHIP_HOMEPAGE_32'),
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formData.value.email) = $event))
                  }, null, 8, _hoisted_21), [
                    [_vModelText, formData.value.email]
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _withDirectives(_createElementVNode("input", {
                    type: "checkbox",
                    class: "form-check-input",
                    id: "privacyCheck",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formData.value.privacyAccepted) = $event))
                  }, null, 512), [
                    [_vModelCheckbox, formData.value.privacyAccepted]
                  ]),
                  _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_9')), 1)
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("button", {
                    type: "submit",
                    class: "bb-btn-submit",
                    disabled: !isFormValid.value
                  }, _toDisplayString(_ctx.$t('CONTACTS_CONTACT')), 9, _hoisted_25)
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_26, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t('TXT_MAIL_SUCCESS_1')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('TXT_MAIL_SUCCESS_2')), 1)
                ], 512), [
                  [_vShow, checkMailSuccess.value]
                ]),
                _withDirectives(_createElementVNode("div", _hoisted_27, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.$t('TXT_MAIL_ERROR_1')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('TXT_MAIL_ERROR_2')), 1)
                ], 512), [
                  [_vShow, checkMailError.value]
                ])
              ], 32)
            ])
          ])
        ]),
        _createElementVNode("section", _hoisted_28, [
          _createVNode(CoockiesBar, { fromBlackship: true })
        ])
      ]),
      _cache[10] || (_cache[10] = _createElementVNode("span", {
        class: "anchor",
        id: "ps"
      }, null, -1)),
      _createElementVNode("section", _hoisted_29, [
        _createElementVNode("div", _hoisted_30, [
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("h4", _hoisted_32, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_10')), 1),
            _createElementVNode("h2", _hoisted_33, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_11')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, [
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("h5", _hoisted_37, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_12')), 1),
              _createElementVNode("p", _hoisted_38, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_13')), 1),
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "icon bb-box-icon" }, [
                _createElementVNode("img", {
                  src: _imports_0,
                  class: "icon-image bb-icon-image"
                })
              ], -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("div", _hoisted_40, [
              _createElementVNode("h5", _hoisted_41, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_14')), 1),
              _createElementVNode("p", _hoisted_42, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_15')), 1),
              _cache[5] || (_cache[5] = _createElementVNode("div", { class: "icon bb-box-icon" }, [
                _createElementVNode("img", {
                  src: _imports_1,
                  class: "icon-image bb-icon-image"
                })
              ], -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_43, [
            _createElementVNode("div", _hoisted_44, [
              _createElementVNode("h5", _hoisted_45, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_16')), 1),
              _createElementVNode("p", _hoisted_46, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_17')), 1),
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "icon bb-box-icon" }, [
                _createElementVNode("img", {
                  src: _imports_2,
                  class: "icon-image bb-icon-image"
                })
              ], -1))
            ])
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_47, [
        _createElementVNode("div", _hoisted_48, [
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "col-sm-6 pt-5 pb-5 text-center" }, [
            _createElementVNode("img", {
              src: _imports_3,
              class: "img-slogan-fluid",
              alt: "Definition"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_49, [
            _createElementVNode("p", _hoisted_50, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_18')), 1),
            _createElementVNode("p", _hoisted_51, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_19')), 1),
            _createElementVNode("div", _hoisted_52, [
              _createElementVNode("div", _hoisted_53, [
                _createElementVNode("div", _hoisted_54, [
                  _createElementVNode("p", _hoisted_55, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_20')), 1),
                  _createElementVNode("span", _hoisted_56, [
                    _cache[7] || (_cache[7] = _createTextVNode(" + ")),
                    (showCounters.value)
                      ? (_openBlock(), _createBlock(_unref(VueNumberAnimation), {
                          key: 0,
                          from: "0",
                          to: "100",
                          format: theFormat,
                          duration: "10",
                          easing: "linear"
                        }))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_57, [
                _createElementVNode("div", _hoisted_58, [
                  _createElementVNode("p", _hoisted_59, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_21')), 1),
                  _createElementVNode("span", _hoisted_60, [
                    _cache[8] || (_cache[8] = _createTextVNode(" + ")),
                    (showCounters.value)
                      ? (_openBlock(), _createBlock(_unref(VueNumberAnimation), {
                          key: 0,
                          from: "0",
                          to: "50",
                          format: theFormat,
                          duration: "10",
                          easing: "linear"
                        }))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_61, [
        _createElementVNode("div", _hoisted_62, [
          _createElementVNode("div", _hoisted_63, [
            _createElementVNode("img", {
              src: sloganImage.value,
              class: "img-slogan-fluid bb-img",
              alt: "Definition"
            }, null, 8, _hoisted_64)
          ]),
          _createElementVNode("div", _hoisted_65, [
            _createElementVNode("img", {
              src: sloganImageMobile.value,
              class: "img-slogan-fluid bb-img",
              alt: "Definition"
            }, null, 8, _hoisted_66)
          ])
        ])
      ]),
      _createElementVNode("section", _hoisted_67, [
        _createElementVNode("div", _hoisted_68, [
          _createElementVNode("div", _hoisted_69, [
            _createElementVNode("h4", _hoisted_70, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_22')), 1),
            _createElementVNode("h2", _hoisted_71, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_23')), 1)
          ]),
          _createElementVNode("div", _hoisted_72, [
            _createElementVNode("div", _hoisted_73, [
              _createElementVNode("button", _hoisted_74, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_24')), 1)
            ]),
            _createElementVNode("div", _hoisted_75, [
              _createElementVNode("button", _hoisted_76, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_25')), 1)
            ]),
            _createElementVNode("div", _hoisted_77, [
              _createElementVNode("button", _hoisted_78, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_26')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_79, [
            _createElementVNode("div", _hoisted_80, [
              _createElementVNode("button", _hoisted_81, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_27')), 1)
            ]),
            _createElementVNode("div", _hoisted_82, [
              _createElementVNode("button", _hoisted_83, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_28')), 1)
            ]),
            _createElementVNode("div", _hoisted_84, [
              _createElementVNode("button", _hoisted_85, _toDisplayString(_ctx.$t('BLACKSHIP_HOMEPAGE_29')), 1)
            ])
          ])
        ])
      ])
    ]),
    _createVNode(FooterComponent, { fromBlackship: true })
  ], 64))
}
}

})