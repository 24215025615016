<template>
    <NavbarBlackshipComponent />
    <div class="container">
      <!--HOME-->
      <section class="bb-background-img pb-5 bb-margin-custom">
          <div class="row justify-content-center bb-padding pt-5 pb-5">
            <div class="col-sm-7 align-content-center text-sm-start text-center bb-col">
              <p class="bb-title mb-1" >{{ $t('BLACKSHIP_HOMEPAGE_1') }}</p>
              <p class="bb-title mb-4">{{ $t('BLACKSHIP_HOMEPAGE_2') }}</p>
              <p class="bb-subtitle" v-html="$t('BLACKSHIP_HOMEPAGE_3')"></p>
              <a class="btn btn-lg bb-btn-gradient-green text-uppercase mt-5 rounded-pill mb-5" href="#ps" role="button">{{ $t('HOME_BTN_SHOW_MORE') }}</a>
            </div>
            <div class="col sm-5 px-0 px-xl-3">
              <div class="card  bg-info">
                <h4 class="bb-form-title mb-4">{{ $t('BLACKSHIP_HOMEPAGE_4') }}</h4>
                <p class="card-text-white">{{ $t('BLACKSHIP_HOMEPAGE_5') }}</p>
                <form id="form" @submit.prevent="submitForm">
                  <div class="form-group mb-3 text-start">
                    <label for="name" class="bb-form-txt">{{ $t('BLACKSHIP_HOMEPAGE_6') }}</label>
                    <input type="text" class="form-control" id="name" :placeholder="$t('BLACKSHIP_HOMEPAGE_30')" v-model="formData.name">
                  </div>
                  <div class="form-group mb-3 text-start">
                    <label for="surname" class="bb-form-txt">{{ $t('BLACKSHIP_HOMEPAGE_7') }}</label>
                    <input type="text" class="form-control" id="surname" :placeholder="$t('BLACKSHIP_HOMEPAGE_31')" v-model="formData.surname">
                  </div>
                  <div class="form-group mb-3 text-start">
                      <label for="email" class="bb-form-txt">{{ $t('BLACKSHIP_HOMEPAGE_8') }}</label>
                      <input type="email" class="form-control" id="email" :placeholder="$t('BLACKSHIP_HOMEPAGE_32')" v-model="formData.email">
                  </div>
                    <div class="form-check mb-4 mt-4">
                        <input type="checkbox" class="form-check-input" id="privacyCheck" v-model="formData.privacyAccepted">
                        <label class="form-check-label" for="privacyCheck">
                          {{ $t('BLACKSHIP_HOMEPAGE_9') }}
                        </label>
                    </div>
                    <div class="text-center  mb-4 mt-4">
                        <button type="submit" class="bb-btn-submit" :disabled="!isFormValid">{{ $t('CONTACTS_CONTACT') }}</button>
                    </div>
  
                    <div class="alert alert-success text-center" role="alert" v-show="checkMailSuccess">
                        <strong>{{ $t('TXT_MAIL_SUCCESS_1') }}</strong> {{ $t('TXT_MAIL_SUCCESS_2') }}
                    </div>
  
                    <div class="alert alert-danger text-center" role="alert" v-show="checkMailError">
                        <strong>{{ $t('TXT_MAIL_ERROR_1') }}</strong> {{ $t('TXT_MAIL_ERROR_2') }}
                    </div>
                </form>
              </div>
            </div>
          </div>
        <!-- barra dei coockies -->
        <section id="coockies-bar">
            <CoockiesBar :fromBlackship="true" />
        </section>
      </section>
      <!--SERVIZI PRINCIPALI-->
      <span class="anchor" id="ps"></span>
      <section class="pb -5">
        <div class="row pt-5 bb-padding">
          <div class="col-sm-12 bb-padding mt-3 mb-3">
            <h4 class="text-center bb-txt-sec-name-black mb-5">{{ $t('BLACKSHIP_HOMEPAGE_10') }}</h4>
            <h2 class="text-center bb-txt-sec-title-black mb-5">{{ $t('BLACKSHIP_HOMEPAGE_11') }}</h2>
          </div>
        </div>
        <div class="row justify-content-center bb-padding mb-5 pb-5">
          <div class="col-sm-4 px-xl-3 px-sm-0 px-2 pb-5 pb-sm-0">
            <div class="card-component bb-1">
              <h5 class="card-title mt-4">{{ $t('BLACKSHIP_HOMEPAGE_12') }}</h5>
              <p class="card-text mt-3 px-xl-4">{{ $t('BLACKSHIP_HOMEPAGE_13') }}</p>
              <div class="icon bb-box-icon">
                <img src="@/assets/images/mobile.png" class="icon-image bb-icon-image">
              </div>
            </div>
          </div>
          <div class="col-sm-4 px-xl-3 px-sm-0 px-2 pb-5 pb-sm-0">
            <div class="card-component bb-2">
              <h5 class="card-title mt-4">{{ $t('BLACKSHIP_HOMEPAGE_14') }}</h5>
              <p class="card-text mt-3 px-xl-4">{{ $t('BLACKSHIP_HOMEPAGE_15') }}</p>
              <div class="icon bb-box-icon">
                <img src="@/assets/images/expert.png" class="icon-image bb-icon-image">
              </div>
            </div>
          </div>
          <div class="col-sm-4 px-xl-3 px-sm-0 px-2 pb-5 pb-sm-0">
            <div class="card-component bb-3">
              <h5 class="card-title mt-4">{{ $t('BLACKSHIP_HOMEPAGE_16') }}</h5>
              <p class="card-text mt-3 px-xl-4">{{ $t('BLACKSHIP_HOMEPAGE_17') }}</p>
              <div class="icon bb-box-icon">
                <img src="@/assets/images/research.png" class="icon-image bb-icon-image">
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--IL NOSTRO TEAM-->
      <section class="pb-5 bb-backgroud">
      <div class="row justify-content-center bb-padding pt-5">
        <div class="col-sm-6 pt-5 pb-5 text-center">
          <img src="../../assets/images/blackship-team.png" class="img-slogan-fluid" alt="Definition">
        </div>
        <div class="col-sm-6 col-sm-6 align-content-center text-sm-end text-center">
          <p class="bb-txt-sec-title mb-4" >{{ $t('BLACKSHIP_HOMEPAGE_18') }}</p>
          <p class="bb-txt-sec">{{ $t('BLACKSHIP_HOMEPAGE_19') }}</p>
          <div id="counters" class="row bb-padding-counter pt-3">
            <div class="col-sm-6 col-6">
              <div class="card-component bb-card-counter align-content-center">
                <p class="bb-counter-txt">{{ $t('BLACKSHIP_HOMEPAGE_20') }}</p>
                <span class="bb-counter">
                  +
                  <VueNumberAnimation
                    v-if="showCounters"
                    from=0
                    to=100
                    :format="theFormat"
                    duration="10"
                    easing="linear"
                  />
                </span>
              </div>
            </div>
            <div class="col-sm-6 col-6 mt-5">
              <div class="card-component bb-card-counter align-content-center">
                <p class="bb-counter-txt">{{ $t('BLACKSHIP_HOMEPAGE_21') }}</p>
                <span class="bb-counter">
                  +
                  <VueNumberAnimation
                    v-if="showCounters"
                    from=0
                    to=50
                    :format="theFormat"
                    duration="10"
                    easing="linear"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      </section>
      <!--SLOGAN-->
      <section class=" bb-background-slogan">
        <div class="row justify-content-center bb-padding">
          <div class="col-sm-12 align-content-center text-center bb-slogan">
            <img :src="sloganImage" class="img-slogan-fluid bb-img" alt="Definition">
          </div>
          <div class="col-sm-12 align-content-center text-center bb-slogan-mobile">
            <img :src="sloganImageMobile" class="img-slogan-fluid bb-img" alt="Definition">
          </div>
        </div>
      </section>
      <!--SETTORI-->
      <section class="pb-5 bb-backgroud">
        <div class="row pt-5 bb-padding">
          <div class="col-sm-12 bb-padding mt-3 mb-3">
            <h4 class="text-center bb-txt-sec-name mb-5">{{ $t('BLACKSHIP_HOMEPAGE_22') }}</h4>
            <h2 class="text-center bb-txt-sec-title mb-5">{{ $t('BLACKSHIP_HOMEPAGE_23') }}</h2>
          </div>
          <div class="row justify-content-sm-start justify-content-center">
            <div class="col-auto mx-3">
                <button class=" btn-custom">{{ $t('BLACKSHIP_HOMEPAGE_24') }}</button>
            </div>
            <div class="col-auto mx-3">
                <button class=" btn-custom">{{ $t('BLACKSHIP_HOMEPAGE_25') }}</button>
            </div>
            <div class="col-auto mx-3">
                <button class=" btn-custom">{{ $t('BLACKSHIP_HOMEPAGE_26') }}</button>
            </div>
        </div>
        <div class="row justify-content-sm-end justify-content-center pb-5 pt-sm-5 pt-0">
            <div class="col-auto mx-3">
                <button class=" btn-custom">{{ $t('BLACKSHIP_HOMEPAGE_27') }}</button>
            </div>
            <div class="col-auto mx-3">
                <button class=" btn-custom">{{ $t('BLACKSHIP_HOMEPAGE_28') }}</button>
            </div>
            <div class="col-auto mx-3">
                <button class=" btn-custom">{{ $t('BLACKSHIP_HOMEPAGE_29') }}</button>
            </div>
        </div>
        </div>
      </section>
    </div>
  <FooterComponent :fromBlackship="true"/>
</template>

  
<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import VueNumberAnimation from "vue-number-animation";
import NavbarBlackshipComponent from "@/components/elements/NavbarBlackshipComponent.vue";
import FooterComponent from "@/components/layout/FooterComponent.vue";
import CoockiesBar from '@/components/elements/CoockiesBar.vue';
import { useStore } from 'vuex';
import { SupportedLanguages } from '@/i18n/languages';
import { useEmailServiceBlackship } from '@/services/composables/useEmailServiceBlackship';
import { ContactUsForm } from '@/services/models/contactUsFormData';
import { useFirestore } from '@/services/api/useFirestore';
import { Domain } from '@/services/models/domain';
import { Outcome } from '@/services/models/outcome';

// cambio l'immagine dello slogan dinamicamente a seconda della lingua
const store = useStore();
const selectedLanguage = computed(() => store.state.locale);
const sloganImage = computed(() => {
  switch (selectedLanguage.value) {
    case SupportedLanguages.IT:
      return require('../../assets/images/slogan-it.svg');
    case SupportedLanguages.EN:
      return require('../../assets/images/slogan-en.svg');
    default:
      return require('../../assets/images/slogan-it.svg');
  }
});

const sloganImageMobile = computed(() => {
  switch (selectedLanguage.value) {
    case SupportedLanguages.IT:
      return require('../../assets/images/slogan-mobile-it.svg');
    case SupportedLanguages.EN:
      return require('../../assets/images/slogan-mobile-en.svg');
    default:
      return require('../../assets/images/slogan-mobile-it.svg');
  }
});

// Gestione counters. Si attivano solo quando l'utente passa sopra la porzione di schermata nella quale si trovano
const showCounters = ref(false);
type ObserverCallback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => void;
const observerCallback: ObserverCallback  = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      if (entry.target.id === 'counters') showCounters.value = true;
    }
  });
};
const observer = new IntersectionObserver(observerCallback, {
  threshold: 0.1, // Inizia l'animazione quando il 10% dell'elemento è visibile
});

onMounted(() => {
  observer.observe(document.getElementById('counters') as Element);
});

onUnmounted(() => {
  observer.disconnect();
});

const theFormat =(value:number)=>{
  return Math.round(value);
}

const checkMailSuccess = ref(false);
const checkMailError = ref(false);
const emailOutcome = ref(Outcome.KO);

// Gestione form
const formData = ref({
  name: '',
  surname: '',
  email: '',
  privacyAccepted: false
});

const isFormValid = computed(() =>
    formData.value.name &&
    formData.value.surname &&
    formData.value.email &&
    formData.value.privacyAccepted
);

const submitForm = () => {
  const {sendEmailToBeasy, sendEmailToCustomer} = useEmailServiceBlackship();
  const { registerNewEmail } = useFirestore();

  checkMailError.value = false;
  checkMailSuccess.value = false;
  
  const dataToSend = new ContactUsForm(formData.value.name, formData.value.surname, formData.value.email, emailOutcome.value);

  sendEmailToBeasy(dataToSend)
  .then(() => {
    sendEmailToCustomer(dataToSend)
    .then(() =>{
      checkMailSuccess.value =true;
      dataToSend.emailOutcome = Outcome.OK;

      // Reset form data after all it's done
      formData.value.name = '';
      formData.value.surname = '';
      formData.value.email = '';
      formData.value.privacyAccepted = false;
    }).catch(mailError);
  })
  .catch(mailError)
  .finally(() => registerNewEmail(dataToSend, Domain.BLACKSHIP));
};

function mailError() {
  console.log(checkMailError.value);
  checkMailError.value = true;
}

</script>
  
<style scoped lang="scss">

.bb-margin-custom {
  margin-top:118px
}

.anchor {
    display: block;
    height: 105px;
    margin-top: -105px;
    visibility: hidden;
}

.bb-icon-image{
  height: 80px;
}

.btn-custom{
  background-color: #0a3a4a;
  border: 2px solid #5dc4ea;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  color: white;
  border-radius: 50px;
  padding: 10px 60px;
  margin: 10px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  font-size: 28px;
  height: 90px;
}

.bb-slogan{
  min-height: 70vh;
}

.bb-slogan-mobile{
    display: none;
  }


.bb-counter {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size:40px;
  color: #5DC4EA;
}

.bb-counter-txt {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size:30px;
  color: #FFFFFF;
}

.img-slogan-fluid{
    width: 70% !important;
}

.bb-padding-counter{
  padding-left: 10rem;
}

.bb-card-counter{
    border: 1px solid #5dc4ea !important;
    background: #04364D 0% 0% !important;
    min-height: 150px !important;
    max-width: 210px;
}

.bb-1{
  background:transparent linear-gradient(134deg, #37D599 0%, #32CCA4 17%, #28BABA 49%, #199FDA 100%) 0% 0% no-repeat padding-box !important;
}

.bb-2{
  background:transparent linear-gradient(137deg, #5DC4EA 0%, #199FDA 100%) 0% 0% no-repeat padding-box !important;
}

.bb-3{
  background:transparent linear-gradient(136deg, #5DC4EA 0%, #6148EA 100%) 0% 0% no-repeat padding-box !important;
}

.bb-box-icon{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.bb-col{
  min-height: 25rem;
}

.container, .row {
    --bs-gutter-x: 0.0rem;
}

.btn-lg:hover{
    box-shadow: 0 0 30px grey;
    padding: 18px 30px;
    font-size: 1.4em;
}

.card-component {
  background: linear-gradient(to bottom right, #3baebf, #3799c4);
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  color: #000;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  margin: 10px;
}
.card-title {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 18px;
}

.card-text {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.card-text-white {
  font-family: "Inter", sans-serif;
  font-weight: 200;
  font-size: 16px;
  color: #FFFFFF;
}

.icon {
  font-size: 30px;
}

button {
  border-radius: 30px;
}


.bb-btn-gradient-green:hover {
  background: linear-gradient(90deg, #218838 0%, #1e7e34 100%);
}

@media (min-width: 2800px) {
  .bb-title{
    font-size: 80px;
  }
  .bb-subtitle {
    font-size: 40px;
  }

  .bb-counter{
    font-size: 70px;
  }

  .bb-counter-txt{
    font-size: 55px;
  }

  .bb-card-counter{
    min-height: 300px !important;
    max-width: 400px;
  }

  .card-text,  .card-text-white{
    font-size: 26px;
  }

  .bb-margin-custom {
    margin-top:158px
  }

  .btn-custom{
    padding: 20px 170px;
  }

  .card-title{
    font-size: 34px;
  }

  .card-text {
    min-height: 230px;
  }
    
}

@media only screen and (min-width: 2300px) and (max-width:2799px){

  .bb-counter{
    font-size: 60px;
  }

  .bb-counter-txt{
    font-size: 45px;
  }

  .bb-card-counter{
    min-height: 250px !important;
    max-width: 300px;
  }

  .card-title{
    font-size: 28px;
  }

  .btn-custom{
    padding: 10px 105px;
  }

  .bb-margin-custom {
    margin-top:137px
  }

  .card-text,  .card-text-white{
    font-size: 22px;
  }

  .card-text {
    min-height: 210px;
  }
}


@media only screen and (min-width:1025px) and (max-width:1440px) {

  .btn-custom{
    padding: 10px 40px;
    font-size: 22px;
    height: 75px;
    margin: 0px;
  }

  .bb-counter {
    font-size:36px;
  }

  .bb-counter-txt {
    font-size:26px;
  }

  .bb-slogan{
    min-height: 70vh;
  }

  .bb-padding-counter{
    padding-left: 6rem;
  }


}

@media only screen and (min-width:769px) and (max-width:1024px) {

  .bb-margin-custom {
  margin-top:108px
}

  .bb-icon-image{
    height: 70px;
  }

  .btn-custom{
    padding: 10px 30px;
    font-size: 18px;
    height: 60px;
    margin: 0px;
  }

  .bb-counter {
    font-size:34px;
  }

  .bb-counter-txt {
    font-size:24px;
  }


  .card-text,  .card-text-white{
    font-size: 14px;
  }

  .bb-slogan{
    min-height: 60vh;
  }

  .bb-padding-counter{
    padding-left: 3rem;
  }

}

@media only screen and (min-width:426px) and (max-width:768px) {

  .img-slogan-fluid{
    width: 80% !important;
  }

  .bb-margin-custom {
    margin-top:108px
  }

  .bb-icon-image{
    height: 60px;
  }

  .btn-custom{
    padding: 10px 25px;
    font-size: 14px;
    height: 50px;
    margin: 0px;
  }

  .bb-counter {
    font-size:30px;
  }

  .bb-counter-txt {
    font-size:20px;
  }

  .card-text,  .card-text-white{
    font-size: 10px;
  }

  .bb-slogan{
    min-height: 45vh;
  }

  .bb-padding-counter{
    padding-left: 2rem;
  }

}

@media only screen and (max-width: 425px) {

  .img-slogan-fluid{
    width: 90% !important;
}

  .bb-margin-custom {
  margin-top:108px
}

  .bb-counter {
    font-size:28px;
  }

  .bb-counter-txt {
    font-size:18px;
  }

  .card-text,  .card-text-white{
    font-size: 14px;
  }

  .bb-card-counter{
    min-height: 100px !important;
  }

  .bb-counter, .bb-counter-s{
    font-size: 23px;
  }

  .bb-slogan, .bb-slogan-mobile{
    min-height: 50vh;
  }

  .bb-slogan-mobile{
    display: inline;
  }

  .bb-slogan{
    display: none;
  }


  .bb-padding-counter{
    padding-left: 0rem;
  }

}

@media only screen and (max-width: 603px) {
  .btn-custom{
    font-size: 16px;
    height: 60px;
    margin-bottom: 2rem;
    padding: 10px 60px;
  }
}

@media only screen and (max-width: 576px) {
  .bb-icon-image{
    height: 70px;
  }
}

</style>
