<script setup lang="ts">
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const store = useStore();
const { locale } = useI18n();

const languages = {
    en: 'ENG',
    it: 'ITA'
};

const selectedLanguage = ref(store.state.locale);

// Watch per aggiornare `locale` di i18n e salvare la lingua nello store
watch(selectedLanguage, (newLang) => {
    locale.value = newLang; // aggiorna `locale` di i18n
    store.commit('setLocale', newLang); // salva nello store e nel localStorage se impostato nello store
});

</script>

<template>
    <div class="mr-3 ml-3">
        <select v-model="selectedLanguage" class="form-select">
            <option class="bb-select-item" v-for="(lang, key) in languages" :key="key" :value="key">{{ lang }}</option>
        </select>
  </div>
</template>

<style lang="css" scoped>

.form-select {
    background: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27white%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e);
    display: block;
    width: 5rem;
    padding: .375rem 2.25rem .375rem .75rem;
    line-height: 1.5;
    appearance: none;
    background-color: rgba(1, 33, 47) !important;
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: none;
    color: #FFFFFF !important;
    text-transform: uppercase !important;
    font-family: "Inter", sans-serif  ;
    font-weight: 200 ;
    font-size: 12px ;
}

.form-select:focus {
    border:none;
    border-color: #199FDA;
    outline: 0;
    box-shadow:none;
}

.bb-select-item{
    color: #FFFFFF !important;
    text-transform: uppercase !important;
    font-family: "Inter", sans-serif  !important;
    font-weight: 200 !important;
    font-size: 12px !important;
}
</style>