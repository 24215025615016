import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/icons/bb-footer-logo.svg'
import _imports_1 from '@/assets/images/iso-logo-footer.png'
import _imports_2 from '@/assets/images/SA_cert.png'
import _imports_3 from '@/assets/images/logos/likedin-logo.svg'


const _hoisted_1 = { class: "d-flex flex-wrap justify-content-between align-items-center bb-footer pt-5 pb-5" }
const _hoisted_2 = { class: "col-md-6 d-flex align-items-center justify-content-center mb-5 mb-md-0 me-md-auto text-body-secondary bb-pos" }
const _hoisted_3 = { class: "bb-txt-center" }
const _hoisted_4 = { class: "bb-footer-txt" }
const _hoisted_5 = { class: "bb-footer-txt-s" }
const _hoisted_6 = { class: "bb-footer-txt-s" }




export default /*@__PURE__*/_defineComponent({
  __name: 'FooterComponent',
  props: {
  fromBlackship:{
    type: Boolean
  }
},
  setup(__props) {

const props = __props



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "nav col-md-3 mb-3 justify-content-center mb-5 mb-sm-3 mt-5 mt-sm-3 bb-pos" }, [
      _createElementVNode("a", { href: "#" }, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "",
          class: "bb-logo-footer"
        })
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('FOOTER_1')), 1),
        (props.fromBlackship)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: { name: 'TermsAndConditionsBlackship' }
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('FOOTER_2')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!props.fromBlackship)
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              to: { name: 'TermsAndConditions' }
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('FOOTER_2')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"nav col-md-3 justify-content-center mb-5 mb-md-0 bb-pos\" data-v-46a52e0e><a href=\"https://firebasestorage.googleapis.com/v0/b/b4b-website-dev.appspot.com/o/Certificato_9001.pdf?alt=media&amp;token=c7e88fb2-0939-4524-944e-d67e0882f4b5\" target=\"_blank\" data-v-46a52e0e><img src=\"" + _imports_1 + "\" alt=\"\" class=\"mx-3 bb-logo-footer\" data-v-46a52e0e></a><a href=\"https://firebasestorage.googleapis.com/v0/b/b4b-website-dev.appspot.com/o/Certificato_SA8000.pdf?alt=media&amp;token=0d6c3bbc-6b9a-41ab-8ee6-3baeb6b885f4\" target=\"_blank\" data-v-46a52e0e><img src=\"" + _imports_2 + "\" alt=\"\" class=\"bb-logo-footer\" data-v-46a52e0e></a></div><div class=\"col-sm-12 d-flex align-items-center justify-content-center pt-1 pb-4 bb-pos\" data-v-46a52e0e><a href=\"https://www.linkedin.com/company/beasy4biz\" target=\"_blank\" data-v-46a52e0e><img src=\"" + _imports_3 + "\" alt=\"\" class=\"mx-3 bb-logo-footer-small\" data-v-46a52e0e></a></div>", 2))
  ]))
}
}

})