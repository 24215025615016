<template>
    <NavbarComponent />
    <div class="container-xxl bb-backgroud">
        <div class="row pt-5 pt-custom bb-padding">
            <div class="col-sm-5 align-self-top pb-4 text-center text-sm-start">
                <h4 class="bb-txt-sec-name mb-4 mt-5">{{ $t('WORK_WITH_US_TITLE') }}</h4>
                <h2 class="bb-txt-sec-title mb-4  ">{{ $t('WORK_WITH_US_CV') }}</h2>
                <p class="bb-txt-sec  ">{{ $t('WORK_WITH_US_SUBTITLE') }}</p>
            </div>
            <div class="col-sm-7 align-self-center text-center text-sm-end">
                <img src="../../assets/images/work-with-us.png" alt="Lavoro" class="img-wrk rounded mx-auto">
            </div>
        </div>
        <div class="row bb-white pt-5">
            <div class="col-sm-12 bb-padding text-center text-sm-start">
                <h4 class="bb-txt-sec-name mb-4 txt-c">{{ $t('WORK_WITH_US_POS_OPEN') }}</h4>
                <h2 class="bb-txt-sec-title mb-5 txt-c">{{ $t('WORK_WITH_US_SEARCH') }}</h2>
            </div>
            <div class="col-sm-12 pb-5 bb-padding">
                <div class="bb-padding-custom">
                    <InrecruitingComponent />
                </div>
            </div>
        </div>
    </div>
    <FooterComponent />
</template>

<script setup lang="ts">
import NavbarComponent from "@/components/elements/NavbarComponent.vue";
import FooterComponent from "@/components/layout/FooterComponent.vue";
import InrecruitingComponent from '../elements/InrecruitingComponent.vue';

import { onMounted } from 'vue';

onMounted(() =>{
 window.scrollTo(0,0);
});


</script>

<style scoped lang="scss">
.img-wrk {
    width: 65%;
    height: auto;
}
.pt-custom{
    padding-top: 10rem !important;
    padding-bottom: 3rem !important;
}

@media (min-width: 2800px) {
    .pt-custom{
        padding-top: 15rem !important;
        padding-bottom: 7rem !important;
    }
    
}

@media only screen and (min-width: 2300px) and (max-width:2799px){
    .pt-custom{
        padding-top: 13rem !important;
        padding-bottom: 5rem !important;
    }
}

@media only screen and (min-width:426px) and (max-width:768px) {
    .img-wrk {
        width: 75%;
        height: auto;
    }
}

@media only screen and (max-width: 425px) {
    .img-wrk {
        width: 90%;
        height: auto;
    }
    .pt-custom{
    padding-top: 7rem !important;
    padding-bottom: 3rem !important;
}


}

.bb-white{
    background: #fff;
}

.txt-c{
    color: #082d3e !important;
}

@media only screen and (min-width:426px) and (max-width:768px) {
    .img-fluid {
        max-width: 90% !important;
        height: auto !important;
    }
}

@media only screen and (max-width: 425px) {
    .img-fluid {
        max-width: 90% !important;
        height: auto !important;
    }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
    .img-fluid {
        max-width: 80% !important;
        height: auto !important;
    }
}

.img-fluid {
    max-width: 50% ;
    height: auto;
}

</style>
