export class ContactUsForm {
    name:  string;
    surname: string;
    email: string;
    note?: string;
    emailOutcome: string;

    constructor(
        name: string,
        surname: string,
        email: string,
        emailOutcome: string,
        note?: string
    ){
        this.name = name;
        this.surname = surname;
        this.email = email;
        this.emailOutcome = emailOutcome;
        this.note = note;
    }
}