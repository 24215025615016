<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 pb-5 pt-5">
        <h3 class="text-center bb-txt-sec-title">{{ $t('WHAT_DIFF_TITLE') }}</h3>
      </div>
    </div>
      <div class="row pb-5 bb-padding">
        <div class="col-sm-4 text-center mt-4 mb-4 px-sm-3">
          <div class="circle-icon pb-4">
            <img src="../../assets/images/conoscenze.svg" class="img-fluid" alt="" />
          </div>
          <p class="bb-txt mt-5">{{ $t('WHAT_DIFF_TECH_1') }}<br>{{ $t('WHAT_DIFF_TECH_2') }}<br>{{ $t('WHAT_DIFF_TECH_3') }}<br>{{ $t('WHAT_DIFF_TECH_4') }}</p>
        </div>
        <div class="col-sm-4 text-center mt-4 mb-4 px-sm-3">
          <div class="circle-icon pb-4">
            <img src="../../assets/images/competitivita.svg" class="img-fluid" alt="" />
          </div>
          <p class="bb-txt mt-5">{{ $t('WHAT_DIFF_COST_1') }}<br>{{ $t('WHAT_DIFF_COST_2') }}<br>{{ $t('WHAT_DIFF_COST_3') }}<br>{{ $t('WHAT_DIFF_COST_4') }}</p>
        </div>
        <div class="col-sm-4 text-center mt-4 mb-4 px-sm-3 ">
          <div class="circle-icon pb-4">
            <img src="../../assets/images/team.svg" class="img-fluid" alt="" />
          </div>
          <p class="bb-txt mt-5">{{ $t('WHAT_DIFF_TEAM_1') }}<br>{{ $t('WHAT_DIFF_TEAM_2') }}<br>{{ $t('WHAT_DIFF_TEAM_3') }}<br>{{ $t('WHAT_DIFF_TEAM_4') }}</p>
        </div>
      </div>
  </div>
</template>
  
<script setup lang="ts">
  
</script>
  
<style scoped>

.bb-txt{
  color: #FFFFFF;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 18px;
}

@media (min-width: 2800px) {
    .img-fluid {
        height: auto;
        width: 60%;
    }

    .bb-txt{
      font-size: 30px;
    }
    
}

@media only screen and (min-width: 2300px) and (max-width:2799px){

    .img-fluid {
        height: auto;
        width: 60%;
    }

    .bb-txt{
      font-size: 24px;
    }
}

</style>
  