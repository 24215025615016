import { collection, addDoc } from 'firebase/firestore';
import { ContactUsForm } from '../models/contactUsFormData';
import { FirestoreCollections } from '../api/firestoreCollections';
import { db } from '../../firebase/firebaseConfig';
import { Domain } from '../models/domain';

export function useFirestore(){
    const _insertDocument = async (collectionName: string, data: Record<string, unknown>): Promise<void> =>{
        await addDoc(
            collection(db, collectionName), 
            data);
    }

    const registerNewEmail = async (data: ContactUsForm, domain: Domain): Promise<void> => {
        try {
            await _insertDocument(
                FirestoreCollections.EMAIL_HISTORY,
                { 
                    name: data.name, 
                    surname: data.surname, 
                    email: data.email, 
                    domain: domain,
                    email_outcome: data.emailOutcome,
                    timestamp: new Date() 
                },
            )
        } catch (err){
            console.error("Errore durante il censimento della mail");
        }
    }

    return {registerNewEmail};
}