import { ContactUsForm } from "../models/contactUsFormData";
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { IEmailService } from "../interfaces/emailServiceInterface";

/**
 * Email services implementation for Blackship landing page
 * @returns a service to send an email to Beasy4BIZ team and a service to send an email to che customer
 */
export function useEmailServiceBlackship(): IEmailService {
    
    const serviceID = process.env.VUE_APP_BLACKSHIP_EMAILJS_SERVICE_ID;
    const userID = process.env.VUE_APP_BLACKSHIP_EMAILJS_USER_ID;
    const templateMailBeasy = process.env.VUE_APP_BLACKSHIP_EMAILJS_TEMPLATE_MAIL_BEASY;
    const templateMailCustomer = process.env.VUE_APP_BLACKSHIP_EMAILJS_TEMPLATE_MAIL_CUSTOMER;  

    const sendEmailToBeasy = async (data: ContactUsForm): Promise<EmailJSResponseStatus> => {
        return emailjs.send(serviceID, templateMailBeasy,
            {
              name: data.name,
              surname: data.surname,
              email: data.email
            }, 
            userID);
    }

    const sendEmailToCustomer = async (data: ContactUsForm): Promise<EmailJSResponseStatus> => {
        return emailjs.send(
            serviceID, 
             templateMailCustomer, 
            {
                name: data.name + ' ' + data.surname,
                email: data.email
            }, 
            userID);
    }

    return {
        sendEmailToBeasy,
        sendEmailToCustomer,
    };
}