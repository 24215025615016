import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/icons/bb-logo.svg'


const _hoisted_1 = { class: "navbar fixed-top navbar-expand-lg navbar-light bb-navbar px-3" }
const _hoisted_2 = { class: "container-fluid mt-3 mb-3 p-0" }
const _hoisted_3 = { class: "justify-content-end bb-padding" }
const _hoisted_4 = { class: "order-1 order-md-2" }

import LanguageComponent from "@/components/elements/LanguageComponent.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'NavbarBlackshipComponent',
  setup(__props) {

    
return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        class: "navbar-brand bb-padding",
        to: { name: 'blackship' }
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "",
            class: "bb-logo-header"
          }, null, -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(LanguageComponent)
        ])
      ])
    ])
  ]))
}
}

})