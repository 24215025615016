<template>
  <footer class="d-flex flex-wrap justify-content-between align-items-center bb-footer pt-5 pb-5">
    <div class="nav col-md-3 mb-3 justify-content-center mb-5 mb-sm-3 mt-5 mt-sm-3 bb-pos">
        <a href="#">
            <img  src="@/assets/images/icons/bb-footer-logo.svg" alt="" class="bb-logo-footer">
        </a>
    </div>

    <div class="col-md-6 d-flex align-items-center justify-content-center mb-5 mb-md-0 me-md-auto text-body-secondary bb-pos">
        <span class="bb-txt-center">
            <p class="bb-footer-txt">{{ $t('FOOTER_1') }}</p>
              <router-link v-if="props.fromBlackship" :to="{ name: 'TermsAndConditionsBlackship' }"><span class="bb-footer-txt-s">{{ $t('FOOTER_2') }}</span></router-link>
              <router-link v-if="!props.fromBlackship" :to="{ name: 'TermsAndConditions' }"><span class="bb-footer-txt-s">{{ $t('FOOTER_2') }}</span></router-link>
        </span>    
    </div>

    <div class="nav col-md-3 justify-content-center mb-5 mb-md-0 bb-pos">
        <a href="https://firebasestorage.googleapis.com/v0/b/b4b-website-dev.appspot.com/o/Certificato_9001.pdf?alt=media&token=c7e88fb2-0939-4524-944e-d67e0882f4b5" target="_blank">
            <img  src="@/assets/images/iso-logo-footer.png" alt="" class="mx-3 bb-logo-footer" >
        </a>
        <a href="https://firebasestorage.googleapis.com/v0/b/b4b-website-dev.appspot.com/o/Certificato_SA8000.pdf?alt=media&token=0d6c3bbc-6b9a-41ab-8ee6-3baeb6b885f4" target="_blank">
            <img  src="@/assets/images/SA_cert.png" alt="" class="bb-logo-footer" >
        </a>
    </div>


      <div class="col-sm-12 d-flex align-items-center justify-content-center pt-1 pb-4 bb-pos">
        <a href="https://www.linkedin.com/company/beasy4biz" target="_blank">
          <img  src="@/assets/images/logos/likedin-logo.svg" alt=""  class="mx-3 bb-logo-footer-small">
        </a>
      </div>


  </footer>
</template>
  
<script setup lang="ts">
import { defineProps } from 'vue';

const props = defineProps({
  fromBlackship:{
    type: Boolean
  }
})


</script>
  
<style scoped lang="scss">

.bb-logo-footer-small{
  width: 30px;
  height: auto;
}

.bb-logo-footer{
  width: 70px;
  height: auto;
}

@media (min-width: 2800px) {

  .bb-logo-footer-small{
    width: 50px;
    height: auto;
  }

  .bb-logo-footer{
    width: 120px;
    height: auto;
  }

}

@media only screen and (min-width: 2300px) and (max-width:2799px){

  .bb-logo-footer-small{
    width: 40px;
    height: auto;
  }

  .bb-logo-footer{
    width: 90px;
    height: auto;
  }

}

@media only screen and (min-width:426px) and (max-width:768px) {
  .bb-logo-footer{
    width: 60px;
    height: auto;
  }
}

@media only screen and (max-width: 425px) {

  .bb-logo-footer{
    width: 60px;
    height: auto;
  }
  .bb-pos{
    width: 100%; 
  }
}
</style>
