<template>
    <NavbarComponent />
    <section class="bb-background-section-1 text-light text-center">
        <div class="container mt-5 p-0">
            <!-- Video -->
            <header class="mt-5">
                <!-- This div is  intentionally blank. It creates the transparent black overlay over the video which you can modify in the CSS -->
                <div class="overlay"></div>

                <!-- The HTML5 video element that will create the background video on the header -->
                <video autoplay loop muted playsinline>
                <source src="https://firebasestorage.googleapis.com/v0/b/b4b-website-dev.appspot.com/o/Video%20full%20page%20B4B.mp4?alt=media&token=ed52bd46-f11f-43b8-bb08-4c126ee07cb2" type="video/mp4">
                </video>

                <!-- The header content -->
                <div class="container h-100">
                <div class="d-flex h-100 text-center align-items-center">
                    <div class="w-100 text-white">
                        <p class="bb-title">{{ $t('HOME_TITLE') }}</p>
                        <a class="btn btn-lg bb-btn-gradient-green text-uppercase mt-5 rounded-pill mb-5" href="#who-we-are" role="button">{{ $t('HOME_BTN_SHOW_MORE') }}</a>
                    </div>
                </div>
                </div>
            </header>
        </div>
        
        <!-- barra dei coockies -->
        <section id="coockies-bar">
            <CoockiesBar/>
        </section>
    </section>

   
   <section class="bb-backgroud" id="who-we-are">
        <WhoWeAreComponent />
    </section>
 
    <section class="bb-background-section-2" id="our-customers">
        <OurCustomersComponent />
    </section>

    <section class="bb-backgroud" id="what-we-do">
        <WhatWeDoComponent />
    </section> 

    <section class=" bb-background-section-3 p-0" id="differentiates">
        <WhatDifferentiatesUsComponent />
    </section>

    <section class="bb-backgroud-2" id="technologies">
        <TechnologiesComponent />
    </section>
    
    <section class=" bb-backgroud" id="contacts">
        <ContactsComponent />
    </section>
<FooterComponent/>
</template>

  
<script setup lang="ts">
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ContactsComponent from "../layout/ContactsComponent.vue";
import WhatWeDoComponent from "../layout/WhatWeDoComponent.vue";
import WhoWeAreComponent from "../layout/WhoWeAreComponent.vue";
import OurCustomersComponent from './OurCustomersComponent.vue';
import WhatDifferentiatesUsComponent from "../layout/WhatDifferentiatesUsComponent.vue";
import TechnologiesComponent from './TechnologiesComponent.vue';
import CoockiesBar from '../elements/CoockiesBar.vue';
import NavbarComponent from "../../components/elements/NavbarComponent.vue";
import FooterComponent from "../../components/layout/FooterComponent.vue";

const route = useRoute();
const router = useRouter();

const sections = ['who-we-are', 'what-we-do', 'contacts'];
let observer: IntersectionObserver;

const scrollToSectionFromQuery = () => {
  const section = route.query.section as string || route.hash.substring(1);
  if (section) {
    const element = document.getElementById(section);
    if (element) {
      // Esegui il scroll dopo un piccolo ritardo
      setTimeout(() => {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth',
        });
      }, 100);
      router.replace({ path: '/' });
    }
  }
};

const observeSections = () => {
  observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let sectionId = entry.target.id;

          if (sectionId === 'who-we-are') {
            sectionId = 'about';
          }
          if (sectionId === 'what-we-do') {
            sectionId = 'services';
          }
          if (sectionId === 'contacts') {
            sectionId = 'contacts';
          }

          if (window.location.pathname !== `/${sectionId}`) {
            window.history.pushState(null, '', `/${sectionId}`);
          }
        }
      });
    },
    { threshold: 0.3 }
  );

  sections.forEach((sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) observer.observe(sectionElement);
  });
};

onMounted(() => {
    scrollToSectionFromQuery();
    observeSections();
});
</script>
  
<style scoped lang="scss">


header {
  position: relative;
  background-color: black;
  height: 100vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

header video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

header .container {
  position: relative;
  z-index: 2;
}

header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.1;
  z-index: 1;
}

</style>
