import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-xxl" }
const _hoisted_2 = { class: "row bb-backgroud bb-padding-top bb-padding" }
const _hoisted_3 = { class: "col-sm-12 mb-5" }
const _hoisted_4 = { class: "bb-txt-sec-name mb-4" }
const _hoisted_5 = { class: "bb-txt-sec-title mb-4" }
const _hoisted_6 = { class: "row bb-padding" }
const _hoisted_7 = {
  class: "col-sm-12 py-3 mb-5",
  style: {"background":"white"}
}
const _hoisted_8 = { class: "bb-info-title" }
const _hoisted_9 = { class: "bb-info-txt" }
const _hoisted_10 = { class: "bb-info-title" }
const _hoisted_11 = { class: "bb-info-subtitle" }
const _hoisted_12 = { class: "bb-info-txt mb-3" }
const _hoisted_13 = { class: "bb-info-subtitle" }
const _hoisted_14 = { class: "bb-info-txt mb-3" }
const _hoisted_15 = { class: "bb-info-subtitle" }
const _hoisted_16 = { class: "bb-info-txt mb-3" }
const _hoisted_17 = { class: "bb-info-subtitle" }
const _hoisted_18 = { class: "bb-info-txt mb-3" }
const _hoisted_19 = { class: "bb-info-subtitle" }
const _hoisted_20 = { class: "bb-info-txt" }
const _hoisted_21 = {
  class: "bb-info-txt mb-3",
  type: "a"
}
const _hoisted_22 = { class: "bb-info-subtitle" }
const _hoisted_23 = { class: "bb-info-txt mb-3" }
const _hoisted_24 = { class: "bb-info-txt mb-3" }
const _hoisted_25 = { class: "bb-info-subtitle" }
const _hoisted_26 = { class: "bb-info-txt" }
const _hoisted_27 = { class: "bb-info-txt mb-3" }
const _hoisted_28 = { class: "bb-info-txt mb-3" }
const _hoisted_29 = { class: "bb-info-txt" }
const _hoisted_30 = { class: "bb-info-subtitle" }
const _hoisted_31 = { class: "bb-info-txt" }
const _hoisted_32 = { class: "bb-info-subtitle" }
const _hoisted_33 = { class: "bb-info-txt mb-3" }
const _hoisted_34 = { class: "bb-info-txt mb-3" }
const _hoisted_35 = { class: "bb-info-txt mb-3" }
const _hoisted_36 = { class: "bb-info-txt mb-3" }
const _hoisted_37 = { class: "bb-info-subtitle" }
const _hoisted_38 = { class: "bb-info-txt" }
const _hoisted_39 = { class: "bb-info-txt mb-3" }
const _hoisted_40 = { class: "bb-info-subtitle" }
const _hoisted_41 = { class: "bb-info-txt" }
const _hoisted_42 = { class: "bb-info-txt" }
const _hoisted_43 = { class: "bb-info-txt mb-3" }
const _hoisted_44 = { class: "bb-info-subtitle" }
const _hoisted_45 = { class: "bb-info-txt mb-3" }
const _hoisted_46 = { class: "bb-info-subtitle" }
const _hoisted_47 = { class: "bb-info-txt mb-3" }
const _hoisted_48 = { class: "bb-info-subtitle" }
const _hoisted_49 = { class: "bb-info-txt mb-3" }
const _hoisted_50 = { style: {"margin-left":"2rem"} }
const _hoisted_51 = { class: "bb-info-txt mb-3" }
const _hoisted_52 = { class: "bb-info-txt mb-3" }
const _hoisted_53 = { class: "bb-info-txt mb-3" }
const _hoisted_54 = { class: "bb-info-txt mb-3" }
const _hoisted_55 = {
  class: "bb-info-txt mb-3",
  type: "a"
}
const _hoisted_56 = { class: "bb-info-txt mb-3" }
const _hoisted_57 = { class: "bb-info-txt mb-3" }
const _hoisted_58 = { class: "bb-info-txt mb-3" }
const _hoisted_59 = { class: "bb-info-txt mb-3" }
const _hoisted_60 = { class: "bb-info-subtitle" }
const _hoisted_61 = { class: "bb-info-txt mb-3" }
const _hoisted_62 = { class: "bb-info-subtitle" }
const _hoisted_63 = { class: "bb-info-txt mb-3" }
const _hoisted_64 = { class: "bb-info-txt mb-3" }
const _hoisted_65 = { class: "bb-info-txt mb-3" }
const _hoisted_66 = { class: "bb-info-subtitle" }
const _hoisted_67 = { class: "bb-info-txt mb-3" }
const _hoisted_68 = { class: "bb-info-subtitle" }
const _hoisted_69 = { class: "bb-info-txt mb-3" }
const _hoisted_70 = { class: "bb-info-subtitle" }
const _hoisted_71 = { class: "bb-info-txt" }
const _hoisted_72 = { class: "bb-info-txt mb-3" }
const _hoisted_73 = { style: {"margin-left":"2rem"} }
const _hoisted_74 = { class: "bb-info-subtitle" }
const _hoisted_75 = { class: "bb-info-txt mb-3" }
const _hoisted_76 = { class: "bb-info-subtitle" }
const _hoisted_77 = { class: "bb-info-txt mb-3" }
const _hoisted_78 = { class: "bb-info-subtitle" }
const _hoisted_79 = { style: {"margin-left":"2rem"} }
const _hoisted_80 = { class: "bb-info-subtitle" }
const _hoisted_81 = { class: "bb-info-txt mb-3" }
const _hoisted_82 = { class: "bb-info-subtitle" }
const _hoisted_83 = { class: "bb-info-txt mb-3" }
const _hoisted_84 = { class: "bb-info-subtitle" }
const _hoisted_85 = { class: "bb-info-txt mb-3" }
const _hoisted_86 = { class: "bb-info-subtitle" }
const _hoisted_87 = { class: "bb-info-txt mb-3" }
const _hoisted_88 = { class: "table-responsive-md mt-4 mb-4" }
const _hoisted_89 = { class: "table table-bordered" }
const _hoisted_90 = { class: "" }
const _hoisted_91 = {
  scope: "col",
  class: "bb-info-subtitle col-2"
}
const _hoisted_92 = {
  scope: "col",
  class: "bb-info-subtitle col-2"
}
const _hoisted_93 = {
  scope: "col",
  class: "bb-info-subtitle col-2"
}
const _hoisted_94 = {
  scope: "col",
  class: "bb-info-subtitle col-2"
}
const _hoisted_95 = {
  scope: "col",
  class: "bb-info-subtitle col-4"
}
const _hoisted_96 = { class: "bb-info-txt" }
const _hoisted_97 = { class: "bb-info-subtitle" }
const _hoisted_98 = { class: "bb-info-txt mb-3" }
const _hoisted_99 = { class: "bb-info-subtitle" }
const _hoisted_100 = { class: "bb-info-txt mb-3" }
const _hoisted_101 = { class: "bb-info-subtitle" }
const _hoisted_102 = { class: "bb-info-txt mb-3" }
const _hoisted_103 = { class: "bb-info-subtitle" }
const _hoisted_104 = { class: "bb-info-txt mb-3" }
const _hoisted_105 = { style: {"margin-left":"2rem"} }
const _hoisted_106 = { class: "bb-info-subtitle" }
const _hoisted_107 = { class: "bb-info-txt mb-3" }
const _hoisted_108 = { class: "bb-info-subtitle" }
const _hoisted_109 = { class: "bb-info-txt mb-3" }
const _hoisted_110 = { class: "bb-info-subtitle" }
const _hoisted_111 = { class: "bb-info-txt mb-3" }
const _hoisted_112 = { class: "bb-info-subtitle" }
const _hoisted_113 = { class: "bb-info-txt mb-3" }
const _hoisted_114 = { class: "bb-info-subtitle" }
const _hoisted_115 = { class: "bb-info-txt mb-3" }
const _hoisted_116 = { class: "bb-info-txt mb-3" }
const _hoisted_117 = { class: "bb-info-subtitle" }
const _hoisted_118 = { class: "bb-info-txt mb-3" }
const _hoisted_119 = { class: "bb-info-subtitle" }
const _hoisted_120 = { class: "bb-info-txt mb-3" }
const _hoisted_121 = { class: "bb-info-subtitle" }
const _hoisted_122 = { class: "bb-info-txt mb-3" }
const _hoisted_123 = { class: "bb-info-subtitle" }
const _hoisted_124 = { class: "bb-info-txt mb-3" }
const _hoisted_125 = { class: "bb-info-subtitle" }
const _hoisted_126 = { class: "bb-info-txt mb-3" }
const _hoisted_127 = { class: "bb-info-subtitle" }
const _hoisted_128 = { class: "bb-info-subtitle" }
const _hoisted_129 = { class: "bb-info-txt mb-3" }
const _hoisted_130 = { class: "bb-info-subtitle" }
const _hoisted_131 = { class: "bb-info-txt mb-3" }
const _hoisted_132 = { class: "bb-info-subtitle" }
const _hoisted_133 = { class: "bb-info-subtitle" }
const _hoisted_134 = { class: "bb-info-txt mb-3" }
const _hoisted_135 = { class: "bb-info-subtitle" }
const _hoisted_136 = { class: "bb-info-txt mb-3" }
const _hoisted_137 = { class: "bb-info-subtitle" }
const _hoisted_138 = { class: "bb-info-subtitle" }
const _hoisted_139 = { class: "bb-info-txt mb-3" }
const _hoisted_140 = { class: "bb-info-subtitle" }
const _hoisted_141 = { class: "bb-info-txt mb-3" }
const _hoisted_142 = { class: "bb-info-subtitle" }
const _hoisted_143 = { class: "bb-info-subtitle" }
const _hoisted_144 = { class: "bb-info-txt mb-3" }
const _hoisted_145 = { class: "bb-info-subtitle" }

import { onMounted } from 'vue';
import NavbarComponent from "@/components/elements/NavbarComponent.vue";
import FooterComponent from "@/components/layout/FooterComponent.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'TermsAndConditions',
  setup(__props) {

onMounted(() =>{
 window.scrollTo(0,0);

});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(NavbarComponent),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t('TERMS_1')), 1),
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('TERMS_2')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('TERMS_3')), 1),
          _createElementVNode("p", _hoisted_9, [
            _createTextVNode(_toDisplayString(_ctx.$t('TERMS_4')), 1),
            _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
            _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
            _createTextVNode(_toDisplayString(_ctx.$t('TERMS_5')) + " ", 1),
            _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
            _createTextVNode(_toDisplayString(_ctx.$t('TERMS_6')) + " ", 1),
            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
            _createTextVNode(_toDisplayString(_ctx.$t('TERMS_7')) + " ", 1),
            _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
            _createTextVNode(_toDisplayString(_ctx.$t('TERMS_8')) + " ", 1),
            _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
            _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
            _createTextVNode(_toDisplayString(_ctx.$t('TERMS_9')), 1)
          ]),
          _cache[19] || (_cache[19] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('TERMS_10')), 1),
          _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('TERMS_11')), 1),
          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('TERMS_12')), 1),
          _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('TERMS_13')), 1),
          _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t('TERMS_14')), 1),
          _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('TERMS_15')), 1),
          _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t('TERMS_16', { email: 'info@beasy4biz.com' })), 1),
          _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t('TERMS_17')), 1),
          _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t('TERMS_18')), 1),
          _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t('TERMS_19')), 1),
          _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t('TERMS_20')), 1),
          _createElementVNode("ol", _hoisted_21, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_21')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_22')), 1)
          ]),
          _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.$t('TERMS_23')), 1),
          _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.$t('TERMS_24')), 1),
          _cache[20] || (_cache[20] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.$t('TERMS_25')), 1),
          _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.$t('TERMS_26')), 1),
          _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.$t('TERMS_27')), 1),
          _createElementVNode("p", _hoisted_27, [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('TERMS_28')), 1),
            _createTextVNode(_toDisplayString(_ctx.$t('TERMS_29')), 1)
          ]),
          _createElementVNode("ul", _hoisted_28, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_30')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_31')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_32')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_33')), 1)
          ]),
          _createElementVNode("p", _hoisted_29, [
            _createElementVNode("b", null, _toDisplayString(_ctx.$t('TERMS_34')), 1),
            _createTextVNode(_toDisplayString(_ctx.$t('TERMS_35')), 1)
          ]),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.$t('TERMS_36')), 1),
              _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.$t('TERMS_37')), 1)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.$t('TERMS_38')), 1),
              _createElementVNode("p", _hoisted_33, _toDisplayString(_ctx.$t('TERMS_39')), 1),
              _createElementVNode("ul", null, [
                _createElementVNode("li", null, [
                  _createElementVNode("p", _hoisted_34, _toDisplayString(_ctx.$t('TERMS_40')), 1)
                ]),
                _createElementVNode("li", null, [
                  _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.$t('TERMS_41')), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("p", _hoisted_36, _toDisplayString(_ctx.$t('TERMS_42')), 1),
          _createElementVNode("p", _hoisted_37, _toDisplayString(_ctx.$t('TERMS_43')), 1),
          _createElementVNode("p", _hoisted_38, _toDisplayString(_ctx.$t('TERMS_44')), 1),
          _createElementVNode("ol", _hoisted_39, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_45')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_46')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_47')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_48')), 1)
          ]),
          _createElementVNode("p", _hoisted_40, _toDisplayString(_ctx.$t('TERMS_49')), 1),
          _createElementVNode("p", _hoisted_41, _toDisplayString(_ctx.$t('TERMS_50')), 1),
          _createElementVNode("ul", _hoisted_42, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_51')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_52')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_53')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_54')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_55')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_56')), 1)
          ]),
          _createElementVNode("p", _hoisted_43, _toDisplayString(_ctx.$t('TERMS_57')), 1),
          _createElementVNode("p", _hoisted_44, _toDisplayString(_ctx.$t('TERMS_58')), 1),
          _createElementVNode("p", _hoisted_45, _toDisplayString(_ctx.$t('TERMS_59')), 1),
          _createElementVNode("p", _hoisted_46, _toDisplayString(_ctx.$t('TERMS_60')), 1),
          _createElementVNode("p", _hoisted_47, _toDisplayString(_ctx.$t('TERMS_61')), 1),
          _createElementVNode("p", _hoisted_48, _toDisplayString(_ctx.$t('TERMS_62')), 1),
          _createElementVNode("p", _hoisted_49, _toDisplayString(_ctx.$t('TERMS_63')), 1),
          _createElementVNode("div", _hoisted_50, [
            _createElementVNode("p", _hoisted_51, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_64')), 1),
              _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_65')), 1)
            ]),
            _createElementVNode("p", _hoisted_52, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_66')), 1),
              _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_67')), 1)
            ]),
            _createElementVNode("p", _hoisted_53, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_68')), 1),
              _cache[9] || (_cache[9] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_69')), 1)
            ]),
            _createElementVNode("p", _hoisted_54, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_70')), 1),
              _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_71')), 1)
            ]),
            _createElementVNode("ol", _hoisted_55, [
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_72')), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_73')), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_74')), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_75')), 1)
            ]),
            _createElementVNode("p", _hoisted_56, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_76')), 1),
              _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_77')), 1)
            ]),
            _createElementVNode("p", _hoisted_57, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_78')), 1),
              _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_79')), 1)
            ]),
            _createElementVNode("p", _hoisted_58, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_80')), 1),
              _cache[13] || (_cache[13] = _createElementVNode("br", null, null, -1)),
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_81')), 1)
            ])
          ]),
          _createElementVNode("p", _hoisted_59, _toDisplayString(_ctx.$t('TERMS_82', { email: 'info@beasy4biz.com' })), 1),
          _createElementVNode("p", _hoisted_60, _toDisplayString(_ctx.$t('TERMS_83')), 1),
          _createElementVNode("p", _hoisted_61, _toDisplayString(_ctx.$t('TERMS_84')), 1),
          _createElementVNode("p", _hoisted_62, _toDisplayString(_ctx.$t('TERMS_85')), 1),
          _createElementVNode("p", _hoisted_63, _toDisplayString(_ctx.$t('TERMS_86')), 1),
          _createElementVNode("ul", _hoisted_64, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_87')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_88')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_89')), 1)
          ]),
          _createElementVNode("p", _hoisted_65, _toDisplayString(_ctx.$t('TERMS_90')), 1),
          _createElementVNode("p", _hoisted_66, _toDisplayString(_ctx.$t('TERMS_91')), 1),
          _createElementVNode("p", _hoisted_67, _toDisplayString(_ctx.$t('TERMS_92')), 1),
          _cache[21] || (_cache[21] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("p", _hoisted_68, _toDisplayString(_ctx.$t('TERMS_93')), 1),
          _createElementVNode("p", _hoisted_69, _toDisplayString(_ctx.$t('TERMS_94')), 1),
          _createElementVNode("p", _hoisted_70, _toDisplayString(_ctx.$t('TERMS_95')), 1),
          _createElementVNode("p", _hoisted_71, _toDisplayString(_ctx.$t('TERMS_96')), 1),
          _createElementVNode("p", _hoisted_72, _toDisplayString(_ctx.$t('TERMS_97')), 1),
          _createElementVNode("div", _hoisted_73, [
            _createElementVNode("p", _hoisted_74, _toDisplayString(_ctx.$t('TERMS_98')), 1),
            _createElementVNode("p", _hoisted_75, _toDisplayString(_ctx.$t('TERMS_99')), 1),
            _createElementVNode("p", _hoisted_76, _toDisplayString(_ctx.$t('TERMS_100')), 1),
            _createElementVNode("p", _hoisted_77, _toDisplayString(_ctx.$t('TERMS_101')), 1)
          ]),
          _createElementVNode("p", _hoisted_78, _toDisplayString(_ctx.$t('TERMS_102')), 1),
          _createElementVNode("div", _hoisted_79, [
            _createElementVNode("p", _hoisted_80, _toDisplayString(_ctx.$t('TERMS_103')), 1),
            _createElementVNode("p", _hoisted_81, _toDisplayString(_ctx.$t('TERMS_104')), 1),
            _createElementVNode("p", _hoisted_82, _toDisplayString(_ctx.$t('TERMS_105')), 1),
            _createElementVNode("p", _hoisted_83, _toDisplayString(_ctx.$t('TERMS_106')), 1),
            _createElementVNode("p", _hoisted_84, _toDisplayString(_ctx.$t('TERMS_107')), 1),
            _createElementVNode("p", _hoisted_85, _toDisplayString(_ctx.$t('TERMS_108')), 1)
          ]),
          _createElementVNode("p", _hoisted_86, _toDisplayString(_ctx.$t('TERMS_109')), 1),
          _createElementVNode("ul", _hoisted_87, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_110')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_111')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_112')), 1)
          ]),
          _createElementVNode("div", _hoisted_88, [
            _createElementVNode("table", _hoisted_89, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", _hoisted_90, [
                  _createElementVNode("th", _hoisted_91, _toDisplayString(_ctx.$t('TERMS_113')), 1),
                  _createElementVNode("th", _hoisted_92, _toDisplayString(_ctx.$t('TERMS_114')), 1),
                  _createElementVNode("th", _hoisted_93, _toDisplayString(_ctx.$t('TERMS_115')), 1),
                  _createElementVNode("th", _hoisted_94, _toDisplayString(_ctx.$t('TERMS_116')), 1),
                  _createElementVNode("th", _hoisted_95, _toDisplayString(_ctx.$t('TERMS_117')), 1)
                ])
              ]),
              _createElementVNode("tbody", _hoisted_96, [
                _createElementVNode("tr", null, [
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_118')), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_119')), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_120')), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_121')), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_122')), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_123')), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_124')), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_125')), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_126')), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_127')), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", null, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_128')), 1),
                    _cache[14] || (_cache[14] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_129')), 1),
                    _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_130')), 1),
                    _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_131')), 1)
                  ]),
                  _createElementVNode("td", null, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_132')), 1),
                    _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_133')), 1),
                    _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t('TERMS_134')), 1)
                  ]),
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_135')), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_136')), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_137')), 1)
                ]),
                _createElementVNode("tr", null, [
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_138')), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_139')), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_140')), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_141')), 1),
                  _createElementVNode("td", null, _toDisplayString(_ctx.$t('TERMS_142')), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("p", _hoisted_97, _toDisplayString(_ctx.$t('TERMS_143')), 1),
          _createElementVNode("p", _hoisted_98, _toDisplayString(_ctx.$t('TERMS_144')), 1),
          _createElementVNode("ul", null, [
            _createElementVNode("li", null, [
              _createElementVNode("p", _hoisted_99, _toDisplayString(_ctx.$t('TERMS_145')), 1),
              _createElementVNode("p", _hoisted_100, _toDisplayString(_ctx.$t('TERMS_146')), 1)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", _hoisted_101, _toDisplayString(_ctx.$t('TERMS_147')), 1),
              _createElementVNode("p", _hoisted_102, _toDisplayString(_ctx.$t('TERMS_148')), 1)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("p", _hoisted_103, _toDisplayString(_ctx.$t('TERMS_149')), 1),
              _createElementVNode("p", _hoisted_104, _toDisplayString(_ctx.$t('TERMS_150')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_105, [
            _createElementVNode("p", _hoisted_106, _toDisplayString(_ctx.$t('TERMS_151')), 1),
            _createElementVNode("p", _hoisted_107, _toDisplayString(_ctx.$t('TERMS_152')), 1),
            _createElementVNode("p", _hoisted_108, _toDisplayString(_ctx.$t('TERMS_153')), 1),
            _createElementVNode("p", _hoisted_109, _toDisplayString(_ctx.$t('TERMS_154')), 1),
            _createElementVNode("p", _hoisted_110, _toDisplayString(_ctx.$t('TERMS_155')), 1),
            _createElementVNode("p", _hoisted_111, _toDisplayString(_ctx.$t('TERMS_156')), 1),
            _createElementVNode("p", _hoisted_112, _toDisplayString(_ctx.$t('TERMS_157')), 1),
            _createElementVNode("p", _hoisted_113, _toDisplayString(_ctx.$t('TERMS_158')), 1),
            _createElementVNode("p", _hoisted_114, _toDisplayString(_ctx.$t('TERMS_159')), 1),
            _createElementVNode("p", _hoisted_115, _toDisplayString(_ctx.$t('TERMS_160')), 1)
          ]),
          _createElementVNode("p", _hoisted_116, _toDisplayString(_ctx.$t('TERMS_161')), 1),
          _createElementVNode("p", _hoisted_117, _toDisplayString(_ctx.$t('TERMS_162')), 1),
          _createElementVNode("p", _hoisted_118, _toDisplayString(_ctx.$t('TERMS_163')), 1),
          _createElementVNode("p", _hoisted_119, _toDisplayString(_ctx.$t('TERMS_164')), 1),
          _createElementVNode("p", _hoisted_120, _toDisplayString(_ctx.$t('TERMS_165')), 1),
          _createElementVNode("p", _hoisted_121, _toDisplayString(_ctx.$t('TERMS_166')), 1),
          _createElementVNode("p", _hoisted_122, _toDisplayString(_ctx.$t('TERMS_167')), 1),
          _createElementVNode("p", _hoisted_123, _toDisplayString(_ctx.$t('TERMS_168')), 1),
          _createElementVNode("p", _hoisted_124, _toDisplayString(_ctx.$t('TERMS_169')), 1),
          _createElementVNode("p", _hoisted_125, _toDisplayString(_ctx.$t('TERMS_170')), 1),
          _createElementVNode("p", _hoisted_126, _toDisplayString(_ctx.$t('TERMS_171')), 1),
          _createElementVNode("p", _hoisted_127, _toDisplayString(_ctx.$t('TERMS_172')), 1),
          _createElementVNode("p", _hoisted_128, _toDisplayString(_ctx.$t('TERMS_173')), 1),
          _createElementVNode("ul", _hoisted_129, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_174')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_175')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_176')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_177')), 1)
          ]),
          _createElementVNode("p", _hoisted_130, _toDisplayString(_ctx.$t('TERMS_178')), 1),
          _createElementVNode("ul", _hoisted_131, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_179')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_180')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_181')), 1)
          ]),
          _createElementVNode("p", _hoisted_132, _toDisplayString(_ctx.$t('TERMS_182')), 1),
          _createElementVNode("p", _hoisted_133, _toDisplayString(_ctx.$t('TERMS_183')), 1),
          _createElementVNode("ul", _hoisted_134, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_184')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_185')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_186')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_187')), 1)
          ]),
          _createElementVNode("p", _hoisted_135, _toDisplayString(_ctx.$t('TERMS_188')), 1),
          _createElementVNode("ul", _hoisted_136, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_189')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_190')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_191')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_192')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_193')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_194')), 1)
          ]),
          _createElementVNode("p", _hoisted_137, _toDisplayString(_ctx.$t('TERMS_195')), 1),
          _createElementVNode("p", _hoisted_138, _toDisplayString(_ctx.$t('TERMS_196')), 1),
          _createElementVNode("ul", _hoisted_139, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_197')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_198')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_199')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_200')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_201')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_202')), 1)
          ]),
          _createElementVNode("p", _hoisted_140, _toDisplayString(_ctx.$t('TERMS_203')), 1),
          _createElementVNode("ul", _hoisted_141, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_204')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_205')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_206')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_207')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_208')), 1)
          ]),
          _createElementVNode("p", _hoisted_142, _toDisplayString(_ctx.$t('TERMS_209')), 1),
          _createElementVNode("p", _hoisted_143, _toDisplayString(_ctx.$t('TERMS_210')), 1),
          _createElementVNode("ul", _hoisted_144, [
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_211')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_212')), 1),
            _createElementVNode("li", null, _toDisplayString(_ctx.$t('TERMS_213')), 1)
          ]),
          _cache[22] || (_cache[22] = _createElementVNode("hr", null, null, -1)),
          _createElementVNode("p", _hoisted_145, _toDisplayString(_ctx.$t('TERMS_214')), 1)
        ])
      ])
    ]),
    _createVNode(FooterComponent)
  ], 64))
}
}

})