<template>
  <div class="container pt-5">
    <div class="row pt-5">
      <div class="col-sm-12 align-self-left mb-5 mt-5 bb-padding">
        <p class="bb-txt-sec-name mb-4">{{ $t('CONTACTS_TITLE') }}</p>
        <p class="bb-txt-sec-title mb-4" >{{ $t('CONTACTS_FORM') }}</p>
        <p class="bb-txt-sec">{{ $t('CONTACTS_FORM_INF') }}</p>
    </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-lg-6 bb-padding-box">
        <div class="map-container ratio ratio-16x9 ">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2760.595362111384!2d8.94979751555781!3d46.00597897911232!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4784d9a8edc4e2af%3A0xa1d91a72e34b3ed8!2sVia%20Serafino%20Balestra%2010%2C%206900%20Lugano%2C%20Svizzera!5e0!3m2!1sit!2sit!4v1698165248000!5m2!1sit!2sit" 
            allowfullscreen="" 
            loading="lazy" 
            class="rounded">
          </iframe>
        </div>
      </div>

      <div class="col-sm-12 col-lg-6 bb-padding-box-reverse">
        <div class="card  bg-info">
          <h3 class="bb-form-title mb-4">{{ $t('CONTACTS_INSERT_INFO') }}</h3>
          <form id="form" @submit.prevent="submitForm">
            <div class="form-group mb-3">
              <label for="name" class="bb-form-txt">{{ $t('CONTACTS_FULLNAME') }}</label>
              <input type="text" class="form-control" id="name" :placeholder="$t('PLACEHOLDER_NAME_SURNAME')" v-model="formData.name">
            </div>
            <div class="form-group mb-3">
              <label for="email" class="bb-form-txt">{{ $t('CONTACTS_EMAIL') }}</label>
              <input type="email" class="form-control" id="email" :placeholder="$t('PLACEHOLDER_EMAIL')" v-model="formData.email">
            </div>
            <div class="form-group mb-3">
              <label for="note" class="bb-form-txt">{{ $t('CONTACTS_NOTE') }}</label>
              <textarea class="form-control" id="note" rows="3" :placeholder="$t('PLACEHOLDER_NOTE')"  v-model="formData.note"></textarea>
            </div>
            <div class="form-check mb-4 mt-4">
              <input type="checkbox" class="form-check-input" id="privacyCheck" v-model="formData.privacyAccepted">
              <label class="form-check-label" for="privacyCheck">
                {{ $t('CONTACTS_CONFIRM_PRIVACY') }}
              </label>
            </div>
            <div class="text-center mb-4 mt-4">
              <button type="submit" class="bb-btn-submit" :disabled="!isFormValid">{{ $t('CONTACTS_CONTACT') }}</button>
            </div>

            <div class="alert alert-success text-center" role="alert" v-show="checkMailSuccess">
              <strong>{{ $t('TXT_MAIL_SUCCESS_1') }}</strong> {{ $t('TXT_MAIL_SUCCESS_2') }}
          </div>

          <div class="alert alert-danger text-center" role="alert" v-show="checkMailError">
              <strong>{{ $t('TXT_MAIL_ERROR_1') }}</strong> {{ $t('TXT_MAIL_ERROR_2') }}
          </div>
          </form>
        </div>
      </div>
    </div>

    <div class="row pt-5">
      <hr class="bb-hr">
      <div class="col-sm-12 mb-5 mt-3 bb-padding">
        <h4 class="bb-txt-sec-subtitle mb-4 ">{{ $t('CONTACTS_OUR_HEADQUARTERS') }}</h4>
        <div class="d-flex align-items-start mt-4 ">
          <div class="me-4 me-sm-5 bb-m">
            <img src="../../assets/images/icons/sede icon.svg" alt="icona mappa" class="icon">
          </div>
          <div>
            <h5 class="bb-address-txt-title mb-3 mt-1">{{ $t('CONTACTS_LEGAL_HEADQUARTERS') }}</h5>
            <p class="bb-address-txt mb-0">{{ $t('CONTACTS_ADDRESS') }}</p>
            <p class="bb-address-txt mb-0">{{ $t('CONTACTS_NATION') }}</p>
          </div>
        </div>
      </div>
      <hr class="bb-hr mb-5">
    </div>

  </div>

</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useEmailServiceBeasy } from '@/services/composables/useEmailServiceBeasy';
import { useFirestore } from '@/services/api/useFirestore';
import { ContactUsForm } from '@/services/models/contactUsFormData';
import { Domain } from '@/services/models/domain';
import { Outcome } from '@/services/models/outcome';

const formData = ref({
  name: '',
  email: '',
  note: '',
  privacyAccepted: false
});

const checkMailSuccess = ref(false);
const checkMailError = ref(false);
const emailOutcome = ref(Outcome.KO);

const isFormValid = computed(() =>
      formData.value.name &&
      formData.value.email &&
      formData.value.note &&
      formData.value.privacyAccepted
    );

const submitForm = () => {
  const {sendEmailToBeasy, sendEmailToCustomer} = useEmailServiceBeasy();
  const { registerNewEmail } = useFirestore();

  checkMailError.value = false;
  checkMailSuccess.value = false;

  const dataToSend = new ContactUsForm(formData.value.name, '', formData.value.email, emailOutcome.value, formData.value.note);

  sendEmailToBeasy(dataToSend)
  .then(() => {
    sendEmailToCustomer(dataToSend)
    .then(() =>{
      checkMailSuccess.value =true;
      dataToSend.emailOutcome = Outcome.OK;

      // Reset form data after all it's done
      formData.value.name = '';
      formData.value.email = '';
      formData.value.privacyAccepted = false;
    }).catch(mailError);
  })
  .catch(mailError)
  .finally(() => registerNewEmail(dataToSend, Domain.BEASY_4_BIZ));
};

function mailError() {
  console.log(checkMailError.value);
  checkMailError.value = true;
}
</script>

<style scoped>


.bb-address-txt-title{
    color: #5DC4EA;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    font-size: 20px;
}

.bb-address-txt{
    color: #FFFFFF;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
}

.bb-hr{
  color: #ffff;
}

.bb-padding-box{
  padding: 3rem 5rem 3rem 10rem;
}

.bb-padding-box-reverse{
  padding: 3rem 10rem 3rem 5rem;
}

button {
  border-radius: 30px;
}

.map-container {
  border-radius: 30px;
  overflow: hidden;
  height: 99%;
  min-height: 99%;
}

@media (min-width: 2800px) {
  .bb-m{
    margin-top: 1rem !important;
  }
  .bb-address-txt-title{
    font-size: 30px;
  }

  .bb-address-txt{
    font-size: 24px;
  }

.bb-padding-box{
  padding: 3rem 15rem 3rem 30rem;
}

.bb-padding-box-reverse{
  padding: 3rem 30rem 3rem 15rem;
}
}

@media only screen and (min-width: 2300px) and (max-width:2799px){

  .bb-address-txt-title{
    font-size: 24px;
  }

  .bb-address-txt{
    font-size: 18px;
  }

  .bb-padding-box{
  padding: 3rem 10rem 3rem 20rem;
}

.bb-padding-box-reverse{
  padding: 3rem 20rem 3rem 10rem;
}

}


@media only screen and (min-width:1025px) and (max-width:1440px) {
  .bb-padding-box{
    padding: 2rem 2rem 2rem 4rem;
  }

  .bb-padding-box-reverse{
    padding: 2rem 4rem 2rem 2rem;
  }
}

@media only screen and (min-width:769px) and (max-width:1024px) {
  .bb-padding-box, .bb-padding-box-reverse{
      padding: 2rem 2rem 2rem 2rem !important;
    }

  .card {
    padding:1rem;
  }
}

@media only screen and (min-width:426px) and (max-width:768px) {

  .bb-padding-box, .bb-padding-box-reverse{
      padding: 2rem 2rem 2rem 2rem !important;
    }

  .card {
    padding:1rem;
  }

}

@media only screen and (max-width: 425px) {

  .bb-padding-box{
      padding: 1rem 2rem 1rem 2rem;
    }
  
  .bb-padding-box-reverse{
    padding: 1rem 2rem 1rem 2rem;
  }

  .card {
    padding:1rem;
  }

  .bb-address-txt-title{
    font-size: 16px;
  }

  .bb-address-txt{
    font-size: 12px;
  }

}

</style>